// Customizable Area Start
import React from "react";
import { Box, Typography, Grid, Tabs, Tab, Hidden } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import StudentMyAssessmentsController, {
  Props , assessmentCategoryTabs
} from "./StudentMyAssessmentsController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { TabPanel, a11yProps } from "../../../components/src/CustomTabPanel.web";
import AssessmentItemCard from "./AssessmentItemCard.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import DropdownIcon from "../../../components/src/SVGIcons/DropdownIcon.web";
import CustomButton from "../../../components/src/CustomButton.web";

// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    pageName: {
      marginTop: "16px",
      marginBottom: "28px"
    },
    assessmentListMainContainer: {
      maxHeight: "700vh",
      overflowX: "auto",
      borderRadius: "10px",
      padding: "0px 0px 8px 0px"
    },
    tabButton: {
      backgroundColor: "transparent",
      padding: "10px 4px",
      textAlign: "center",
      textDecoration: "none",
      textTransform: "capitalize",
      display: "inline-block",
      fontSize: "16px",
      opacity:1,
      minWidth:"33.33%",
      "&.Mui-selected": {
        backgroundColor: "transparent",
        color: "#0D46BC !important",
        outline: "none",
        borderBottom:"1px solid #0D46BC",
        fontWeight:600
      },
      "&.MuiTab-root": {
        minHeight:"60px"
      }
    },
    mainPagePadding:{
        paddingRight:"20px"
    },
    tabPanel:{
        padding:"30px 12px 15px 12px"
    },
    tabPanelGraded:{
      padding:"10px 12px 15px 12px"
    },
    tabs:{
      "& div.MuiTabs-scroller": {
        borderBottom:"1px solid #979797",
        "& .MuiTabs-flexContainer": {
          borderBottom:"none",
          justifyContent:"space-between"
        }
      }
    },
    dropdownGrid:{
      gap:"12px",
      marginBottom:"30px"
    }
  });

// Customizable Area End

// Customizable Area Start
export class StudentMyAssessments extends StudentMyAssessmentsController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes , navigation } = this.props;
    const { paginationDueList,studentAssessmentsList , selectedAssessmentCategoryTab , selectedSubjectDDValue , subjectDDMenuItems , gradingComponent , selectedGradingComponentDDValue } = this.state;

    return (
      <Box className={`${classes.childMainWrapperPadding} ${classes.mainPagePadding} ${classes.highlightBgColor} ${classes.secondaryBgColor}`} height={"100%"}>
        <Grid container direction="row">
          <Grid item xs={12}>
            <Box marginTop={"1%"}>
              <Typography className={`${classes.textCommonColor} ${classes.fontText12} ${classes.smallTopHeader}`} component={"span"}>
                My Assessments
              </Typography>
              <Typography component={"span"} className={` ${classes.fontText12} ${classes.fontBold600} ${classes.primaryColor5}`} >
                {` / ${assessmentCategoryTabs[selectedAssessmentCategoryTab].label}`}
              </Typography>
            </Box>
          </Grid>
          <Grid className={classes.pageName} item xs={12}>
            <Typography className={`${classes.textPrimaryColor} ${classes.fontText32} ${classes.fontBold600}`} component={"span"}>
              My Assessments
            </Typography>
          </Grid>
          {/* Select Dropdowns */}
          <Grid item xs={12}>
            <Grid container direction="row">
              <Hidden only={["xs", "sm"]}>
                <Box className={classes.dropdownGrid} display={"flex"} alignItems={"flex-end"}>
                  <Box>
                    <Box marginBottom={"10px"}>
                      <Typography className={`${classes.textCommonColor} ${classes.fontText16}`} component={"span"}>
                        Subject
                      </Typography>
                    </Box>
                    <Box>
                      <CustomSelectDropdown
                      fieldId="select-dropdown-assessment-subject"
                      fieldValue={selectedSubjectDDValue}
                      fieldName="selectedSubjectDDValue"
                      handleChange={this.handleChangeDropdownValue}
                      renderValue={this.renderSubjectDDValue}
                      fieldError={false}
                      fieldMenuItems={subjectDDMenuItems}
                      fieldStyles={selectDropdownStyles}
                      iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                      placeHolderText="Select Value"
                    />
                    </Box>
                  </Box>
                  <Box>
                    <Box marginBottom={"10px"}>
                      <Typography className={`${classes.textCommonColor} ${classes.fontText16}`} component={"span"}>
                        Grading Component
                      </Typography>
                    </Box>
                    <Box>
                      <CustomSelectDropdown
                      fieldId="select-dropdown-assessment-grading-component"
                      fieldValue={selectedGradingComponentDDValue}
                      fieldName="selectedGradingComponentDDValue"
                      handleChange={this.handleChangeDropdownValue}
                      renderValue={this.renderGradingComponenttDDValue}
                      fieldError={false}
                      fieldMenuItems={gradingComponent}
                      fieldStyles={selectDropdownStyles}
                      iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                      placeHolderText="Select Value"
                    />
                    </Box>
                  </Box>
                  <Box>
                   <CustomButton btnText="Search" btnStyle={btnStyle} buttonId={`my-assessment-search`} buttonVariant={"contained"} type={"button"} handleButtonClick={this.handleSearchAssessment} isDisabled={false} />
                  </Box>
                </Box>
              </Hidden>
              <Hidden only={["md", "lg", "xl"]}>
                <Box marginBottom={"15px"} display={"flex"} flexDirection={"column"} justifyContent={"flex-start"}>
                  <Box marginBottom={"10px"}>
                    <Box marginBottom={"8px"}>
                      <Typography className={`${classes.fontText16} ${classes.textCommonColor}`} component={"span"}>
                        Subject
                      </Typography>
                    </Box>
                    <Box>
                      <CustomSelectDropdown
                      fieldId="select-dropdown-assessment-subject-mobile"
                      fieldValue={selectedSubjectDDValue}
                      fieldName="selectedSubjectDDValue"
                      handleChange={this.handleChangeDropdownValue}
                      renderValue={this.renderSubjectDDValue}
                      fieldError={false}
                      fieldMenuItems={subjectDDMenuItems}
                      fieldStyles={selectDropdownStyles}
                      iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                    />
                    </Box>
                  </Box>
                  <Box marginBottom={"10px"}>
                    <Box marginBottom={"8px"}>
                      <Typography className={`${classes.textCommonColor} ${classes.fontText16}`} component={"span"}>
                        Grading Component
                      </Typography>
                    </Box>
                    <Box display={"flex"} justifyContent={"flex-start"}>
                      <CustomSelectDropdown
                      fieldId="select-dropdown-assessment-grading-component-mobile"
                      fieldValue={selectedGradingComponentDDValue}
                      fieldName="selectedGradingComponentDDValue"
                      handleChange={this.handleChangeDropdownValue}
                      renderValue={this.renderGradingComponenttDDValue}
                      fieldError={false}
                      fieldMenuItems={gradingComponent}
                      fieldStyles={selectDropdownStyles}
                      iconComponent={<DropdownIcon width="24" height="24" viewBox="0 0 24 24" stroke={"#888888"} strokeWidth="1.5" fill={"none"} />}
                    />
                    </Box>
                  </Box>
                  <Box marginBottom={"10px"}>
                    <CustomButton btnText="Search" btnStyle={btnStyle} buttonId={`my-assessment-search-mobile`} buttonVariant={"contained"} type={"button"} handleButtonClick={this.handleSearchAssessment} isDisabled={false} />
                  </Box>
                </Box>
              </Hidden>
            </Grid>
          </Grid>
          {/* Assessment List */}
          <Grid item xs={12}>
            <Grid className={`${classes.bgColor} ${classes.assessmentListMainContainer}`} container direction="row">
              <Grid item xs={12}>
                <Tabs
                  value={selectedAssessmentCategoryTab}
                  variant="scrollable"
                  scrollButtons="auto"
                  TabIndicatorProps={{
                    style: {
                      background: "none"
                    }
                  }}
                  className={`${classes.tabs}`}
                  data-testid="student-assessment-tabs"
                  onChange={this.handleChangeAssessmentCategoryTab}
                  aria-label="student-assessment-tabs"
                >
                  {assessmentCategoryTabs.map((tabItem: any, index: number) =>
                    <Tab
                      key={tabItem.id}
                      data-testid={`student-assessment-${tabItem.label}-tab`}
                      label={tabItem.label}
                      wrapped
                      {...a11yProps(index)}
                      className={`${classes.tabButton} ${classes.textPrimaryColor}`}
                    />
                  )}
                </Tabs>
              </Grid>
              <Grid className={selectedAssessmentCategoryTab !==2 ?`${classes.tabPanel}`: `${classes.tabPanelGraded}`} item xs={12}>
                <TabPanel value={selectedAssessmentCategoryTab} index={0}>
                  <AssessmentItemCard 
                  handlePaginationChange={this.handlePaginationChange} 
                  paginationDueList={paginationDueList} 
                  navigation={navigation} assessmentList={studentAssessmentsList} assessmentCategory="due" />
                </TabPanel>
                <TabPanel value={selectedAssessmentCategoryTab} index={1}>
                  <AssessmentItemCard  handlePaginationChange={this.handlePaginationChange} 
                  paginationDueList={paginationDueList}  navigation={navigation} assessmentList={studentAssessmentsList} assessmentCategory="completed" />
                </TabPanel>
                <TabPanel value={selectedAssessmentCategoryTab} index={2}>
                  <AssessmentItemCard  handlePaginationChange={this.handlePaginationChange} 
                  paginationDueList={paginationDueList}  navigation={navigation} assessmentList={studentAssessmentsList} assessmentCategory="graded" />
                </TabPanel>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const btnStyle = {
  width:"130px",
  padding:"10px 10px",
  borderRadius:"10px",
  fontSize:"16px",
  fontWeight:600,
  textAlign:"center"
} as const;

const selectDropdownStyles={
  width:"235px"
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(StudentMyAssessments);
// Customizable Area End
