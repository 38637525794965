import { IBlock } from "../../../../framework/src/IBlock";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";
import { imageLandscape, imagePortrait } from "../../../../components/src/gradebook/assets";
import { Message } from "../../../../framework/src/Message";
import { getStorageData } from "../../../../framework/src/Utilities";
import { toast } from "react-toastify";
import { displayApiErrorMsg, getMessageData } from "../../../../components/src/CommonHelper.web";
import { physicsSubjectIcon } from "../assets";
import { tableColumnTypes } from "../../../../components/src/CustomTable.web";
import { AttandanceTableHead, BehaviorsTableHead, gradebookListTableHead } from "../../assets/tenantDashboardMock";
import { SchoolYearResponse } from "../TenantAdminGradeBookController";
import { isArray } from "lodash";
// Customizable Area Start

const configJSON = require("../config.js");

export const themesList = [
  { 
    id: 1,
    name: "Landscape",
    value: "landscape",   
    image: imageLandscape,
    width:190
  },
  {
    id: 2,
    name: "Portrait",
    value: "portrait",
    image: imagePortrait,
    width:150
  },
];
interface GradeBookTemplate {
  id: string;
  type: "grade_book_template";
  attributes: GradeBookTemplateAttributes;
}

interface GradeBookTemplateAttributes {
  id: number;
  grade_book_id: number;
  template_type: "official_template" | "unofficial_template";
  orientation_type: "landscape_template" | "portrait_template";
  created_at: string;
  updated_at: string;
  template_content_types: TemplateContentType[];
}

export interface TemplateContentType {
  id: number;
  grade_book_template_id: number;
  data_block_type: "data_blocks" | "text_editor";
  page_type: "front_page" | "back_page";
  data_block_content_type: "grades" | "behaviour" | "student_information" | "attendence" | null;
  row_number: number;
  column_number: number;
  created_at: string;
  updated_at: string;
  description: string | null;
}
export interface Templates {
  official_templates: {
      data: GradeBookTemplate[];
  };
  unofficial_templates: {
      data: GradeBookTemplate[];
  };
}

export type TemplateTypeKey = 'official_templates' | 'unofficial_templates';

// Customizable Area End
// export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes?: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  selectedThemeType: any;
  selectedAccentColor: string;
  selectedHighlightColor: string;
  gradeBookId:any
  openEditTemplate:boolean;
  templateType:string;
  getDataOfTemplate:any;
  orientationType:string
  getTemplateId:number;
  AttandanceTableHead:any
  AttandanceTableData:any
  gradebookListTableHead:any
  gradebookTableData:Array<any>;
  behaviourTableHead:any;
  behaviourTableData:any;
  schoolYearData:string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class GradeBookTemplateOrientationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  createTemplateApiCallId:string="";
  apiGetTemplateApiCallId:string="";
  updateTemplateApiCallId:string="";
  apiGetTemplateTableDataApiCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseToken),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      selectedThemeType: "landscape",
      selectedAccentColor: "",
      selectedHighlightColor: "",
      gradeBookId:-1,
      openEditTemplate:false,
      templateType:"",
      getDataOfTemplate:[],
      orientationType:"",
      getTemplateId:-1,
      AttandanceTableHead:AttandanceTableHead,
      AttandanceTableData:[],
      gradebookListTableHead:gradebookListTableHead,
      gradebookTableData:[],
      behaviourTableHead:BehaviorsTableHead,  
      behaviourTableData:[],
      schoolYearData:""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleSetUserRole()
    const historyLocationState = this.props.navigation?.getHistoryState();
    this.setState({templateType:historyLocationState.templateType},()=>{
      this.handleGetTemplateApi()
      this.handleGetGradebookTemplateTableDataApi()
    })
    
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  // Customizable Area Start
  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, responseJson } = getMessageData(
        message
      );
 
      if (apiRequestCallId != null) {
        switch (apiRequestCallId) {
          case this.createTemplateApiCallId:
              this.handleCreateTemplateResponse(responseJson);
              break;
          case this.apiGetTemplateApiCallId:
              this.handleGetTemplateResponse(responseJson);
              break;
          case this.updateTemplateApiCallId:
              this.handleUpdateTemplateResponse(responseJson)
              break;
          case this.apiGetTemplateTableDataApiCallId:
            this.handleGetGradebookTableDataResponse(responseJson);
            break;
        }
      }
    }
  }
  handleChangeThemeType = (value: string) => {
    this.setState({ selectedThemeType: value });
  };

  handleBackFromEditTemplate=()=>{
    this.setState({ openEditTemplate: false });
  }
  handleSaveDetails = () => {
    this.setState({ openEditTemplate: true });
  };
  handleSetUserRole = async() => {
    const gradeBookId = this.props.navigation?.getParam("gradebookId");
    if (gradeBookId) {
      this.setState({gradeBookId:gradeBookId})
    }
  };

  handleBack = ()=>{
    const messageGradeBook: Message = new Message(getName(MessageEnum.NavigationMessage))
    messageGradeBook.addData(
      getName(MessageEnum.NavigationTargetMessage),
      'TenantAdminSetupDashboard'
    );
    messageGradeBook.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    const raiseMessageGradeBook: Message = new Message(
      getName(MessageEnum.NavigationPayLoadMessage)
    );
    raiseMessageGradeBook.addData(getName(MessageEnum.SessionResponseData), {
      tabOpen:7,
      showGradeDetails:true,
      gradeBookId:this.state.gradeBookId
    })
    messageGradeBook.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessageGradeBook);
    this.send(messageGradeBook);
  }

  handlCreateTemplateApi =async (requestBody:any) =>{
    const requestUrl = configJSON.templateEndpoint
    const token = await getStorageData("token");
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: token,
      };
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)   
      );
      this.createTemplateApiCallId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        requestUrl
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(requestBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeAddDetail
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  }
  handleCreateTemplateResponse = async(responseJson: {
    data: any
    errors: Array<{}>;})=>{
      if (responseJson?.data) {  
      this.handleBack()
      toast.success("Template created successfully!");
      } else {
        displayApiErrorMsg(responseJson?.errors, this.props.navigation);
      }
  }
  handleGetTemplateApi = async () => {
   
    const requestUrl = `${configJSON.templateEndpoint}?grade_book_id=${this.state.gradeBookId}`;;
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetTemplateApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleGetTemplateResponse = async(responseJson: {
    data: Templates[];
    errors: Array<{}>;})=>{

    if (responseJson?.data) {  
      const selectedTemplateType = this.state.templateType + "s" as TemplateTypeKey;

      const selectedTemplateData = responseJson.data[0][selectedTemplateType];
  
      if (selectedTemplateData?.data && selectedTemplateData.data.length > 0) {
          const orientationType = selectedTemplateData.data[0].attributes?.orientation_type;
          const getData = selectedTemplateData.data[0]?.attributes.template_content_types;
          const getDataId = selectedTemplateData.data[0]?.attributes.id;
  
          if (orientationType === "portrait_template") {
              this.setState({ selectedThemeType: "portrait", orientationType: "portrait" });
          } else {
              this.setState({ selectedThemeType: "landscape", orientationType: "landscape" });
          }
          this.setState({ getDataOfTemplate: getData, getTemplateId: getDataId });
      } else {
          this.setState({  orientationType: "", getDataOfTemplate: null, getTemplateId: -1 });
      }
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
   
  }
   
  handleUpdateTemplateApi =async (requestBody:any,gradeBookTemplateId:number) =>{
    const requestUrl = `${configJSON.templateEndpoint}/${gradeBookTemplateId}`;
    const token = await getStorageData("token");
      const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: token,
      };
    
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.updateTemplateApiCallId= requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        requestUrl
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(requestBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeUpdate
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  }
  handleUpdateTemplateResponse = async(responseJson: {
    data:any;
    errors: Array<{}>;})=>{
      if (responseJson?.data) {  
      this.handleBack()
      toast.success("gradebook Template updated successfully!");
      } else {
        displayApiErrorMsg(responseJson?.errors, this.props.navigation);
      }
  }
  handleGetGradebookTemplateTableDataApi = async () => {
    const requestEndpoint = `${configJSON.gradebookTableEndpoint}?grade_book_id=${this.state.gradeBookId}`;;
    const token = await getStorageData("token");
    const header = {
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetTemplateTableDataApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestEndpoint
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  getRandomValues = () => Math.floor(Math.random() * 11);
  createGradeBookData = (
    subjectData: any, 
    dynamicColumns: { id: string }[]
  ) => {
    return {
      id: subjectData.id,
      Name: {
        name: subjectData.name,
        icon: subjectData.icon,
        bgColor: subjectData.bgColor,
      },
      ...dynamicColumns.reduce((acc:{ [key: string]: number }, semester, index) => {
        const dynamicKey = semester.id 
        acc[dynamicKey] = this.getRandomValues(); 
        return acc;
      }, {}),
      GPA: subjectData.gpa,  
      Grade: subjectData.grade,
    };
  };
  GenerateBehaviorData = (id: number, title: string, dynamicColumns: { id: string }[]) => {
    return {
      id,
      title,
      ...dynamicColumns.reduce((acc:{ [key: string]: number }, semester, index) => {
        const dynamicKey = semester.id 
        acc[dynamicKey] = this.getRandomValues(); 
        return acc;
      }, {})
    };
  };
  handleGetGradebookTableDataResponse = async(responseJson: {
    data: SchoolYearResponse | [];
    errors: Array<{}>;})=>{

    if (responseJson?.data && !isArray(responseJson?.data)) {  
      const resData = responseJson.data.attributes.semesters;
      const schoolYearData = responseJson.data.attributes.title;
      let BehaviorsTableHeadData = [...BehaviorsTableHead];
      let gradebookListTableHeadData = [...gradebookListTableHead];
      let AttandanceTableHeadData = [...AttandanceTableHead];
      

      const dynamicColumns = resData.map((semester, index) => ({
        columnId: index + 2,
        maxWidth: "100%",
        id: semester.title,
        type: tableColumnTypes.TEXT,
        width: "100%",
        label: `${semester.title}`, 
       
      }));
      gradebookListTableHeadData.splice(1, 4, ...dynamicColumns)
      BehaviorsTableHeadData.splice(1,4,...dynamicColumns)
      AttandanceTableHeadData.splice(1, 4, ...dynamicColumns);
      const totalGradeSubject = {
        id: 2,
        name: "Total Grade Point Average",
        gpa: 7,
        grade: "A",
      };

      const physicsSubject = {
        id: 1,
        name: "Physics",
        icon: physicsSubjectIcon,
        bgColor: "#01C996",
        gpa: 7,
        grade: "A",

        
      };
      
      const gradeBookTableData = [
        this.createGradeBookData(physicsSubject, dynamicColumns),  
        this.createGradeBookData(totalGradeSubject, dynamicColumns), 
      ];

      const behaviorTitles = [
        { id: 1, title: "Focused Attention" },
        { id: 2, title: "Focused Obedience" },
        { id: 3, title: "Truthfulness" }
      ];
      
      const BehaviorsTableData = behaviorTitles.map(({ id, title }) => this.GenerateBehaviorData(id, title,dynamicColumns));
     
      const AttandanceTableData = [
        {
          id: 1,
          attendance: "No of School Days",
          ...dynamicColumns.reduce((acc:{ [key: string]: number }, semester, index) => {
            const dynamicKey = semester.id 
            acc[dynamicKey] = this.getRandomValues(); 
            return acc;
          }, {}),
          total: dynamicColumns.reduce((total, semester) => total + this.getRandomValues(), 0) // Calculate total
        }
      ]
     
     
      
      this.setState({
        gradebookListTableHead:gradebookListTableHeadData,
        AttandanceTableData:AttandanceTableData,
        AttandanceTableHead:AttandanceTableHeadData,
        gradebookTableData:gradeBookTableData,
        behaviourTableHead:BehaviorsTableHeadData,
        schoolYearData:schoolYearData,
        behaviourTableData:BehaviorsTableData
      })
    }else if(isArray(responseJson?.data)) {
      this.setState({
        gradebookListTableHead:gradebookListTableHead,
        AttandanceTableData:[],
        AttandanceTableHead:AttandanceTableHead,
        gradebookTableData:[],
        behaviourTableHead:BehaviorsTableHead,
        behaviourTableData:[],  
        schoolYearData:""
      })
    }
     else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
   
  }
  // Customizable Area End
}
