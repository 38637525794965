// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area End

// Customizable Area Start
export interface IDropdownItems {
  id:any;
  label:string;
  value:any;
}
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  assessmentList:any;
  assessmentCategory:string;
  paginationDueList?:any
  handlePaginationChange?: any
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  gradeList: Array<IDropdownItems>;
  selectedGrade:any;
  sortByList: Array<IDropdownItems>;
  selectedSortBy:any;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class AssessmentItemCardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      loading: false,
      gradeList: [
        {
          id: 1,
          label: "Grade 1",
          value: 1,
        },
        {
          id: 2,
          label: "Grade 2",
          value: 2,
        },
        {
          id: 3,
          label: "Grade 3",
          value: 3,
        },
        {
          id: 3,
          label: "Grade 4",
          value: 4,
        },
      ],
      selectedGrade:"",
      sortByList: [
        {
          id: "date",
          label: "Date",
          value: "date",
        },
        {
          id: "grade",
          label: "Grade",
          value: "grade",
        }
      ],
      selectedSortBy:""
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  renderSortDDValue = (selected:any) => {
    if (!selected || selected.length === 0) {
      return "Select Option";
    }
    const { sortByList } = this.state;
    const selectedSortBy = sortByList.find((sort : any) => sort?.id === selected);
    return selectedSortBy?.label;
  };

  renderGradeDDValue = (selected:any) => {
    if (!selected || selected.length === 0) {
      return "Select Option";
    }
    const { gradeList } = this.state;
    const selectedGrade = gradeList.find((subject : any) => subject?.id === selected);
    return selectedGrade?.label;
  };

  handleSearchGradedAssessment = () => {
    console.log("search graded assessment" , this.state.selectedGrade , this.state.selectedSortBy);
  };

  handleNavigateToAssessmentDueScreen = (title : string , typeOfAssessment : string,evaluationType:string,objectiveType:string,activityId:string) => {
    const { assessmentCategory } = this.props;
    if(assessmentCategory === "due"){
      this.props.navigation?.navigate("StudentDueAssessmentMain" , {} , {
        typeOfAssessment : typeOfAssessment,
        evaluationType,
        objectiveType: objectiveType || evaluationType,
        title : title,
        activityId
      });
    } else if(assessmentCategory === "completed") {
      this.props.navigation?.navigate("StudentCompletedAssessmentMain" , {} , {
        typeOfAssessment : typeOfAssessment,
        title : title,
        assessmentCategory:"submitted"
      });
    } else{
        this.props.navigation?.navigate("StudentGradedAssessmentMain" , {} , {
          typeOfAssessment : typeOfAssessment,
          title : title,
          assessmentCategory:"graded"
        });
    }
  };

  handleChangeDropdownValueGradedAssessment = (event: React.ChangeEvent<{
    name?: any;
    value: unknown;
}>, child: React.ReactNode) => {
    const { name, value } = event.target;
    let fieldValue : any = value ;
    if(fieldValue){
      this.setState((prev) => {
        return {
            ...prev,
            [name] : fieldValue,
        };
     });
    }
  };
  // Customizable Area End
}
// Customizable Area End
