// Customizable Area Start
import React from "react";
import { Box, Button, Divider, Grid, Typography } from "@material-ui/core";
import {
  createStyles,
  withStyles,
  Theme,
  ThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import TeacherDashboardStudentController, {
  Props,
} from "./TeacherDashboardStudentController.web";
import CustomTable from "../../../components/src/CustomTable.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import CustomSelectDropdown from "../../../components/src/CustomSelectDropdown.web";
import DropdownIcon from "../../../components/src/SVGIcons/DropdownIcon.web";

import CustomTextField from "../../../components/src/CustomTextField.web";
import SearchIcon from "../../../components/src/SVGIcons/SearchIcon.web";
import ReschedullingModal from "../../cfresumejourney/src/ReschedullingModal.web";
import CustomEmptyResultComponent from "../../../components/src/CustomEmptyResultComponent.web";
import Pagination from "@material-ui/lab/Pagination";
import CustomPaginationComponent from "../../../components/src/CustomPaginationComponent.web";
import  CustomButton  from "../../../components/src/CustomButton.web";

// Customizable Area End

// Customizable Area Start

const styles = (theme: Theme) =>
  createStyles({
    subjectIconBgColor: {
      width: "55px",
      height: "55px",
      borderRadius: "50%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: "2px",
    },
    subjectIconImage: {
      width: "55px",
      height: "55px",
      borderRadius: "50%",
    },
    coursesMainBox: {
      height: "180px",
      padding: "15px",
      width: "85%",
      marginBottom: "5%",
      borderRadius: "12px",
      cursor: "pointer",
      [theme.breakpoints.down("xs")]: {
        width: "90%",
      },
    },

    breadCrumbsRoot: {
      margin: theme.spacing(2, 0, 2, 0),
    },
    topBtnsRoot: {
      display: "flex",
      justifyContent: "space-between",
      marginBottom: "20px",
    },
    topBtn: {
      width: "184px",
      height: "50px",
      borderRadius: "12px",
      backgroundColor: "#0D46BC",
      color: "#ffffff",
      textTransform: "capitalize",
      marginRight: "30px",
      "&:hover": {
        backgroundColor: "#0D46BC",
      },
    },
    selectedBoxBorder: {
      border: "2px solid #FBD82C",
    },
    statusCompletions: {
      fontWeight: 500,
      fontSize: "14px",
      color: "#01C996",
      backgroundColor: "#d0f5eb",
      borderRadius: "17px",
      padding: "6px 10px 6px 10px",
    },
    statusLateColor: {
      fontSize: "14px",
      color: "#ED1111",
      backgroundColor: "#fcd3d3",
      borderRadius: "17px",
      padding: "5px 10px 5px 10px",
    },
    onGoingStatus: {
      fontSize: "14px",
      color: "#FBD82C",
      backgroundColor: "#fef8d8",
      borderRadius: "17px",
      padding: "6px 10px 6px 10px",
    },
    scheduledStatus: {
      fontSize: "14px",
      color: "#4DC901",
      backgroundColor: "#def5d0",
      borderRadius: "17px",
      padding: "6px 10px 6px 10px",
    },
    tableTitle: {
      fontSize: 28,
      fontWeight: 500,
    },
    customTableStyle: {
      borderRadius: "10px",
    },
    dividerFullWidth: {
      margin: `5px 0 0 2px`,
    },
    hoveredCourse: {
      position: "relative",

      "&:before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: "rgba(0, 0, 0, 0.3)",
        zIndex: 1,
        opacity: 0,
        transition: "background-color 0.3s ease-in-out",
        borderRadius: "12px",
        pointerEvents: "none",
      },
      "&:hover": {
        backgroundColor: "#666666",
        cursor: "pointer",
      },
      "&:hover:before": {
        opacity: 1,
      },
    },
    hoveredButton: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      padding: theme.spacing(2),
      background: "#0d45bc",
      borderRadius: "12px",
      color: "#fff",
      cursor: "pointer",
      zIndex: 2,
      transition: "opacity 0.3s",
      pointerEvents: "auto",
      textTransform: "capitalize",
      width: "216px",
      fontSize: "16px",
      "&:hover": {
        backgroundColor: "#0D46BC",
      },
    },
    classManagementTable: {
      "& td": {
        paddingTop: "6px !important",
        paddingBottom: "6px !important",
      }
    },
    paginationRoot: {
      disaply: "flex",
      justifyContent: "flex-end",
      '& > *': {
        marginTop: theme.spacing(3),
      },
      '& .MuiPaginationItem-root': {
        borderRadius: '6px',
      },
      '& .MuiPaginationItem-icon': {
        color: '#0D46BC',
      },
      "& .MuiPaginationItem-page": {
        backgroundColor: "#FFFFFF",
      },
      '& .Mui-selected': {
        backgroundColor: '#FFD92E !important',
        color: "#FFFFFF",
      },
    },
    paginationBox: {
      display: 'flex',
      justifyContent: 'end',
      padding: "10px 20px 10px 21px",
      alignItems: 'center',
    },
    textEllipsis: {
      maxWidth: "250px",
      display: "block",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
  });
const themes = createTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        width: "16px",
        height: "16px",
      },
    },
    MuiInputBase: {
      input: {
        "&::placeholder": {
          fontSize: "14px",
        },
      },
    },
    MuiFormControl: {
      root: {
        marginRight: "24px",
        width: "17.1%",
        height: "35px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
  },
});
// Customizable Area End

// Customizable Area Start
export class TeacherDashboardStudent extends TeacherDashboardStudentController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { studentCoursesList, studentListTableBody, pagination, studentPagination } = this.state;

    return (
      <Box
        className={`${classes.childMainWrapperPadding} ${classes.highlightBgColor} ${classes.secondaryBgColor}`}
        height={"100%"}
      >
        <Box className={classes.breadCrumbsRoot}>
          <Typography
            className={`${classes.textCommonColor} ${classes.fontText14}`}
            component={"span"}
          >
            {`Students`}
          </Typography>
        </Box>
        <Box marginBottom={"17px"}>
          <Box marginBottom={"13px"}>
            <Typography
              className={`${classes.textPrimaryColor} ${classes.fontText28} ${classes.fontBold600}`}
              component={"span"}
            >
              Students
            </Typography>
          </Box>
        </Box>
        <Box className={classes.topBtnsRoot}>
          <CustomSelectDropdown
            fieldId="select-dropdown-assessment-grading-component-mobile"
            fieldValue={this.state.selectedGradingComponentDDValue}
            fieldName="selectedGradingComponentDDValue"
            handleChange={this.handleChangeDropdownValues}
            renderValue={this.renderGradingComponenttDDValues}
            fieldError={false}
            fieldMenuItems={this.state.gradingComponent}
            fieldStyles={dropdownStudent}
            iconComponent={
              <DropdownIcon
                width="24"
                height="24"
                viewBox="0 0 24 24"
                stroke={"#888888"}
                strokeWidth="1.5"
                fill={"none"}
              />
            }
          />
          <CustomButton
          btnText="Reschedule class" 
          buttonId={`add-remark-save-button`} 
          btnStyle={{
            width: "184px",
            height: "50px",
            borderRadius: "12px",
            marginRight: "53px"
          }} 
          buttonVariant={"contained"} 
          type={"button"} 
          handleButtonClick={this.handleOpenReschedulModalBox} 
          isDisabled={false} 
          />
        </Box>
        <Grid item xs={12}>
          <Grid container direction="row">
            {studentCoursesList.length > 0 ? studentCoursesList.map((courseItem: any, index: number) => (
              <Grid key={courseItem.id} item xs={12} sm={6} lg={4}>
                <Box
                  data-test-id={`student-subject-card-${index}`}
                  onMouseEnter={() => this.handleMouseEnter(courseItem.id)}
                  onMouseLeave={this.handleMouseLeave}
                  className={`${!courseItem.isAssigned && this.isCourseAssignable(courseItem.startDate as Date) && this.state.hoveredCourse === courseItem.id ? classes.hoveredCourse : ""} ${this.getSelectedCourseBoxClass(courseItem.id)}`}
                  onClick={() => { this.handleLoadCourseStudents(courseItem) }}
                >
                  <Box
                    display="flex"
                    justifyContent={"center"}
                    flexDirection="column"
                  >
                    <Box display={"flex"} alignItems="center">
                      <Box
                        mr={"10px"}
                        bgcolor={courseItem?.subjectBgColor}
                        className={classes.subjectIconBgColor}
                      >
                        <img
                          className={classes.subjectIconImage}
                          src={courseItem?.subjectIcon}
                          alt="Subject Logo"
                        />
                      </Box>
                      <Box>
                        <Typography
                          className={`${classes.textPrimaryColor} ${classes.fontText22} ${classes.textEllipsis}`}
                          style={{ fontWeight: "bold" }}
                          component="span"
                        >
                          {courseItem.subjectName}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      marginTop={"15px"}
                      display={"flex"}
                      alignItems="center"
                    >
                      <Box>
                        <Typography
                          className={`${classes.textCommonColor} ${classes.fontText18}`}
                          component="span"
                        >
                          Due on {courseItem.dueDate}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      marginTop={"10px"}
                      display={"flex"}
                      alignItems="center"
                    >
                      <Box>
                        <Typography
                          className={`${classes.textCommonColor} ${classes.fontText18}`}
                          component="span"
                        >
                          Lessons :
                        </Typography>
                      </Box>
                      <Box ml={"4px"} display="flex" style={{ gap: "4px" }}>
                        <Typography
                          className={`${classes.primaryColor4} ${classes.fontText16}`}
                          component="span"
                        >
                          {courseItem.lessonsCovered}
                        </Typography>
                        <Typography
                          className={`${classes.textCommonColor} ${classes.fontText16}`}
                          component="span"
                        >
                          /
                        </Typography>
                        <Typography
                          className={`${classes.textPrimaryColor} ${classes.fontText16}`}
                          component="span"
                        >
                          {courseItem.lessonsTotal}
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      marginTop={"10px"}
                      display={"flex"}
                      alignItems="center"
                      justifyContent={"space-between"}
                    >
                      <Box>
                        <Typography
                          className={`${classes.primaryColor4} ${classes.fontText16} ${classes.fontBold500}`}
                          component="span"
                        >
                          {`${courseItem.completionProgress}% completed`}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography
                          style={{
                            color: this.getAvgScoreColor(courseItem.status),
                          }}
                          className={`${classes.fontText14} ${classes.fontBold500
                            }
                         ${this.getAvgScoreColor(courseItem.status)}`}
                          component="h5"
                        >
                          {`${courseItem.status}`}
                        </Typography>
                      </Box>
                    </Box>
                   
                    {!courseItem.isAssigned && this.isCourseAssignable(courseItem.startDate as Date) && this.state.hoveredCourse === courseItem.id && (
                      <CustomButton
                      btnText="Assign Course" 
                      data-test-id="assignBtn"
                      buttonId={`assign-course`} 
                      btnStyle={{
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        cursor: "pointer",
                        zIndex: 2,
                        borderRadius: "12px",
                        transition: "opacity 0.3s",
                        pointerEvents: "auto",
                        textTransform: "capitalize",
                        width: "216px",
                        position: "absolute",
                        height: "50px",
                        fontSize: "16px",
                      }} 
                      buttonVariant={"contained"} 
                      type={"button"} 
                      handleButtonClick={() => this.handleStudentCourse(courseItem.id)} 
                      isDisabled={false} 
                      />
                    )}
                  </Box>
                </Box>
              </Grid>
            ))
              :
              <CustomEmptyResultComponent message="No courses found" />
            }
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {studentCoursesList.length > 0 &&
            <Box className={classes.paginationBox}>
              <Box className={classes.paginationRoot}>
                <Pagination
                  shape="rounded"
                  variant="outlined"
                  count={Math.ceil(pagination?.total_count / pagination?.per_page)}
                  siblingCount={0}
                  boundaryCount={1}
                  onChange={this.handlePaginationChange}
                  page={pagination?.current_page}
                />
              </Box>
            </Box>
          }
        </Grid>
        <Box
          mt={4.5}
          style={{ width: "98%" }}
          className={`${classes.customTableStyle} ${classes.bgColor}`}
        >
          <Box
            display={"flex"}
            className={classes.inputFont}
            pt={1.5}
            px={1.6}
            style={{ width: "98%" }}
            alignItems="center"
            justifyContent="space-between"
            mb={1.5}
          >
            <Typography className={`${classes.tableTitle} ${classes.textSecondaryColor}`}>Students</Typography>
            <ThemeProvider theme={themes}>
              <CustomTextField
                placeHolderText="Search by Students"
                fieldName="searchStudent"
                fieldId="search-students"
                fieldValue={this.state.searchStudent}
                fieldWidth={"16.1%"}
                fieldType="text"
                handleChange={this.handleChange}
                startAdornmentIcon={
                  <SearchIcon
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    stroke={"#888888"}
                    strokeWidth="0.45"
                    fill={"#888888"}
                  />
                }
              />
            </ThemeProvider>
          </Box>
          <Divider className={classes.dividerFullWidth} />
          <Box pb={2} pr={1} className={`${classes.bgColor} ${classes.classManagementTable}`} >
            {studentListTableBody.length > 0 ?
              <CustomTable
                tableId={"student-listing-table"}
                tableHeaderColumns={this.state.studentListTableColumns}
                tableBodyData={studentListTableBody}
                showMenuIcon={false}
                isHandleCellAction
                handleAction={this.handleclick}
                handleColumnSorting={this.onColumnSort}
              />
              :
              <CustomEmptyResultComponent message="No students found" />
            }
          </Box>
          {studentListTableBody.length > 0 &&
            <CustomPaginationComponent paginationData={studentPagination} handlePaginationChange={this.handlePagination} />
          }
        </Box>
        <Box>
          <ReschedullingModal
            open={this.state.openRescheduleModalBox}
            onCancel={this.handleCloseAssignCourseModalBox}
            onConfirm={this.handleCloseAssignCourseModalBox}
          />
        </Box>
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const dropdownStudent = {
  width: "200px",
  borderRadius: "8px",
  height: "48px",
} as const;
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(TeacherDashboardStudent);
// Customizable Area End
