import {
  englishSubjectIcon,
  historySubjectIcon,
  mathSubjectIcon,
  physicsSubjectIcon,
  psychologySubjectIcon,
  man2,
  man4,
  man5,
  man6,
} from "../src/assets";
import { tableColumnTypes } from "../../../components/src/CustomTable.web";
import { FIELD_TYPES } from "../../../components/src/CommonType.web";

const subjectsListMock = [
  { id: 2, label: "Mathematics", value: 2 },
  { id: 1, label: "Physics", value: 1 },
  { id: 3, label: "History", value: 3 },
];

const schoolYearListMock = [
  { id: 1, label: "2022-2023", value: 1 },
  { id: 2, label: "2021-2022", value: 2 },
];

const customField1ListMock = [
  { id: 1, label: "Tag 1", value: 1 },
  { id: 2, label: "Tag 2", value: 2 },
];

const customField2ListMock = [
  { id: 1, label: "Tag 3", value: 1 },
  { id: 2, label: "Tag 4", value: 2 },
];

const courseUtilizationListMock = [
  { id: 1, label: "Option 1", value: 1 },
  { id: 2, label: "Option 2", value: 2 },
];

const teacherNameListMock = [
  { id: 1, label: "Anthony Teacher", value: 1 },
  { id: 2, label: "Sam Teacher", value: 2 },
];

const gradeLevelListMock = [
  { id: 1, label: "Grade Level 1", value: 1 },
  { id: 2, label: "Grade Level 2", value: 2 },
];

const curriculumListMock = [
  { id: 1, label: "Curriculum 1", value: 1 },
  { id: 2, label: "Curriculum 2", value: 2 },
];

const titleListMock = [
  { id: 1, label: "Title 1", value: 1 },
  { id: 2, label: "Title 2", value: 2 },
];

const countListMock = [
  { id: 1, label: "1", value: 1 },
  { id: 2, label: "2", value: 2 },
  { id: 3, label: "3", value: 3 },
  { id: 4, label: "4", value: 4 },
  { id: 5, label: "5", value: 5 },
  { id: 6, label: "6", value: 6 },
];

const statusListMock1 = [
  { id: "new", label: "New", value: "new" },
  { id: "modified", label: "Modified", value: "modified" },
];

const acsDescMock = [
  { id: "ascending", label: "Ascending", value: "ascending" },
  { id: "descending", label: "Descending", value: "descending" },
];

const availableCoursesListMock = [
  {
    title: {
      name: "Velocity and Acceleration",
      icon: physicsSubjectIcon,
      bgColor: "#01C996",
      boxWidth: "32px",
      boxHeight: "32px",
    },
    id: "Velocity and Acceleration",
    subjectName: "Science",
    grade: "Grade 3",
    createdOn: "July 20, 2022",
    modifiedOn: "July 20, 2022",
    requestedBy: "Jordana Kitchener",
    acceptedOn: "July 20, 2022",
    requestedOn: "July 20, 2022",
  },
  {
    grade: "Grade 3",
    id: "Profit and Loss",
    subjectName: "Maths",
    type: "Private",
    title: {
      name: "Profit and Loss",
      icon: mathSubjectIcon,
      bgColor: "#0D46BC",
      boxWidth: "32px",
      boxHeight: "32px",
    },
    createdOn: "July 20, 2022",
    modifiedOn: "July 20, 2022",
    requestedBy: "Jordana Kitchener",
    acceptedOn: "July 20, 2022",
    requestedOn: "July 20, 2022",
  },
  {
    title: {
      name: "Roman History",
      icon: historySubjectIcon,
      bgColor: "#FBD82C",
      boxWidth: "32px",
      boxHeight: "32px",
    },
    subjectName: "History",
    grade: "Grade 3",
    type: "Global",
    createdOn: "July 20, 2022",
    id: "Roman History",
    modifiedOn: "July 20, 2022",
    requestedBy: "Kimmy Mcllmorie",
    acceptedOn: "July 20, 2022",
    requestedOn: "July 20, 2022",
  },
  {
    subjectName: "English",
    grade: "Grade 3",
    type: "Private",
    title: {
      name: "Victorian Literature",
      icon: englishSubjectIcon,
      bgColor: "#019AFC",
      boxWidth: "32px",
      boxHeight: "32px",
    },
    createdOn: "July 20, 2022",
    id: "Victorian Literature",
    modifiedOn: "July 20, 2022",
    requestedBy: "Martin Dragonjer",
    acceptedOn: "July 20, 2022",
    requestedOn: "July 20, 2022",
  },
  {
    modifiedOn: "July 20, 2022",
    subjectName: "English",
    grade: "Grade 3",
    type: "Private",
    title: {
      name: "Creative Writing",
      icon: englishSubjectIcon,
      bgColor: "#019AFC",
      boxWidth: "32px",
      boxHeight: "32px",
    },
    createdOn: "July 20, 2022",
    id: "Creative Writing",
    requestedBy: "Naomi Yepes",
    acceptedOn: "July 20, 2022",
    requestedOn: "July 20, 2022",
  },
  {
    subjectName: "English",
    grade: "Grade 3",
    type: "Global",
    title: {
      name: "English Poetry",
      icon: englishSubjectIcon,
      bgColor: "#019AFC",
      boxWidth: "32px",
      boxHeight: "32px",
    },
    createdOn: "July 20, 2022",
    id: "English Poetry",
    modifiedOn: "July 20, 2022",
    requestedBy: "Nayara Delafuente",
    acceptedOn: "July 20, 2022",
    requestedOn: "July 20, 2022",
  },
];

const tenantCatalogCreationStep2InputConfigMock = [
  {
    id: "tenant-course-mng-step2-catalog-title-filter",
    name: "selectedCatalogTitleListDDValue",
    fieldName: "catalogTitleListDDMenuItems",
    value: "",
    menuItems: [
      { id: 1, label: "Title 1", value: 1 },
      { id: 2, label: "Title 2", value: 2 },
    ],
    label: "Title",
    fieldType: FIELD_TYPES.SELECT,
    placeHolderText: "Select Title",
  },
  {
    id: "tenant-course-mng--step2-subject-filter",
    name: "selectedSubjectHandledDDValue",
    fieldType: FIELD_TYPES.SELECT,
    placeHolderText: "Select Subject",
    fieldName: "subjectHandledDDMenuItems",
    value: "",
    menuItems: [
      { id: 2, label: "Mathematics", value: 2 },
      { id: 1, label: "Physics", value: 1 },
      { id: 3, label: "History", value: 3 },
    ],
    label: "Subject",
  },
  {
    name: "selectedGradeLevelDDValue",
    fieldName: "gradeLevelDDMenuItems",
    id: "tenant-course-mng--step2-gradeLevel-filter",
    value: "",
    fieldType: FIELD_TYPES.SELECT,
    placeHolderText: "Select Grade Level",
    menuItems: [
      { id: 1, label: "Grade Level 1", value: 1 },
      { id: 2, label: "Grade Level 2", value: 2 },
    ],
    label: "Grade",
  },
];

const tenantCatalogCreationStep2TableHeaderMock = [
  {
    columnId: 21,
    id: "title",
    label: "Title",
    type: tableColumnTypes.SUBJECT_ICON_NAME,
    width: "250px",
    maxWidth: "280px",
  },
  {
    columnId: 22,
    id: "subjectName",
    label: "Subject",
    type: tableColumnTypes.TEXT,
    width: "80px",
    maxWidth: "120px",
  },
  {
    columnId: 23,
    id: "grade",
    label: "Grade",
    type: tableColumnTypes.TEXT,
    width: "80px",
    maxWidth: "120px",
  },
  {
    columnId: 25,
    id: "createdOn",
    label: "Created On",
    type: tableColumnTypes.TEXT,
    width: "100px",
    maxWidth: "150px",
  },
  {
    id: "modifiedOn",
    columnId: 26,
    label: "Last Modified On",
    type: tableColumnTypes.TEXT,
    width: "100px",
    maxWidth: "150px",
  },
  {
    id: "actionButton",
    columnId: 27,
    label: "",
    type: tableColumnTypes.BUTTON,
  },
];

const catalogTableBodyDataMock = [
  {
    id: 1,
    title: "Catalog 1",
    noOfCourses: 40,
    grade: "Grade 3",
    curriculum: "Curriculum 1",
    customField: "Tag 1",
    createdBy: "Thynker Tech",
  },
  {
    id: 2,
    title: "Catalog 2",
    noOfCourses: 3,
    grade: "Grade 3",
    curriculum: "Curriculum 2",
    customField: "Tag 2",
    createdBy: "Thynker Tech",
  },
  {
    id: 3,
    title: "Catalog 3",
    noOfCourses: 24,
    grade: "Grade 3",
    curriculum: "Curriculum 3",
    customField: "Tag 4",
    createdBy: "Thynker Tech",
  },
  {
    id: 4,
    title: "Catalog 4",
    noOfCourses: 8,
    grade: "Grade 3",
    curriculum: "Curriculum 4",
    customField: "Tag 4",
    createdBy: "Thynker Tech",
  },
  {
    id: 5,
    title: "Catalog 5",
    noOfCourses: 2,
    grade: "Grade 3",
    curriculum: "Curriculum 5",
    customField: "Tag 5",
    createdBy: "DLSU",
  },
  {
    id: 6,
    title: "Catalog 6",
    noOfCourses: 53,
    grade: "Grade 3",
    curriculum: "Curriculum 6",
    customField: "Tag 6",
    createdBy: "ADMU",
  },
];

const teacherCoursesTableBodyDataMock = [
  {
    id: 1,
    title: {
      name: "Physics",
      icon: physicsSubjectIcon,
      bgColor: "#01C996",
      boxWidth: "32px",
      boxHeight: "32px",
    },
    subject: "Science",
    grade: "Grade 3",
    noOfLessonsPerDay: "1 Lesson / day",
    totalNumberOfLessons: 47,
    modifiedBy: "Bairam Frootan",
    createdBy: "Conan Matusov",
    schoolYear: "2022-2023",
    isByTenant: true,
  },
  {
    id: 2,
    title: {
      name: "Algebra",
      icon: mathSubjectIcon,
      bgColor: "#0D46BC",
      boxWidth: "32px",
      boxHeight: "32px",
    },
    subject: "Maths",
    grade: "Grade 3",
    noOfLessonsPerDay: "40 Lessons / Term",
    totalNumberOfLessons: 47,
    modifiedBy: "Chineze Uchechey",
    createdBy: "Jordana Kitchener",
    schoolYear: "2022-2023",
  },
  {
    id: 3,
    title: {
      name: "Roman History",
      icon: historySubjectIcon,
      bgColor: "#FBD82C",
      boxWidth: "32px",
      boxHeight: "32px",
    },
    subject: "History",
    grade: "Grade 3",
    noOfLessonsPerDay: "3 Lessons / day",
    totalNumberOfLessons: 47,
    modifiedBy: "Emlen Beaver",
    createdBy: "Kimmy Mcllmorie",
    schoolYear: "2021-2022",
    isByTenant: true,
  },
  {
    id: 4,
    title: {
      name: "Victorian Literature",
      icon: englishSubjectIcon,
      bgColor: "#019AFC",
      boxWidth: "32px",
      boxHeight: "32px",
    },
    subject: "English",
    grade: "Grade 3",
    noOfLessonsPerDay: "3 Lessons / day",
    totalNumberOfLessons: 47,
    modifiedBy: "Farrokh Rastegar",
    createdBy: "Martijn Dragonjer",
    schoolYear: "2021-2022",
  },
  {
    id: 5,
    title: {
      name: "Creative Writing",
      icon: englishSubjectIcon,
      bgColor: "#019AFC",
      boxWidth: "32px",
      boxHeight: "32px",
    },
    subject: "English",
    grade: "Grade 3",
    noOfLessonsPerDay: "1 Lesson / day",
    totalNumberOfLessons: 47,
    modifiedBy: "Farrokh Rastegar",
    createdBy: "Martijn Dragonjer",
    schoolYear: "2021-2022",
  },
  {
    id: 6,
    title: {
      name: "English Poetry",
      icon: englishSubjectIcon,
      bgColor: "#019AFC",
      boxWidth: "32px",
      boxHeight: "32px",
    },
    subject: "English",
    grade: "Grade 3",
    noOfLessonsPerDay: "1 Lesson / day",
    totalNumberOfLessons: 47,
    modifiedBy: "Farrokh Rastegar",
    createdBy: "Martijn Dragonjer",
    schoolYear: "2021-2022",
    isByTenant: true,
  },
  {
    id: 7,
    title: {
      name: "Astronomy",
      icon: physicsSubjectIcon,
      bgColor: "#01C996",
      boxWidth: "32px",
      boxHeight: "32px",
    },
    subject: "Science",
    grade: "Grade 3",
    noOfLessonsPerDay: "1 Lesson / day",
    totalNumberOfLessons: 53,
    modifiedBy: "Jelanie Frootan",
    createdBy: "Conan Matusov",
    schoolYear: "2022-2023",
  },
];

const coursesTableHeaderColumnsMock = [
  {
    columnId: 11,
    id: "title",
    label: "Catalog Name",
    type: tableColumnTypes.CATALOG_NAME,
  },
  {
    columnId: 12,
    id: "noOfCourses",
    label: "No. of Courses",
    type: tableColumnTypes.TEXT,
  },
  {
    columnId: 13,
    id: "grade",
    label: "Grade",
    type: tableColumnTypes.TEXT,
  },
  {
    id: "curriculum",
    columnId: 14,
    label: "Curriculum",
    type: tableColumnTypes.TEXT,
  },
  {
    id: "createdBy",
    columnId: 6,
    label: "Created By",
    type: tableColumnTypes.TEXT,
  },
];

const managePendingRequestsTableHeaderColumnsMock = [
  {
    columnId: 31,
    id: "title",
    label: "Title",
    type: tableColumnTypes.SUBJECT_ICON_NAME,
    width: "250px",
    maxWidth: "300px",
  },
  {
    columnId: 32,
    id: "subjectName",
    label: "Subject",
    type: tableColumnTypes.TEXT,
    width: "100px",
    maxWidth: "150px",
  },
  {
    columnId: 33,
    id: "grade",
    label: "Grade",
    type: tableColumnTypes.TEXT,
    width: "100px",
    maxWidth: "150px",
  },
  {
    id: "requestedBy",
    columnId: 34,
    label: "Requested By",
    type: tableColumnTypes.TEXT,
    width: "150px",
    maxWidth: "200px",
  },
  {
    columnId: 35,
    id: "requestedOn",
    label: "Requested On",
    type: tableColumnTypes.TEXT,
    width: "120px",
    maxWidth: "200px",
  },
  {
    columnId: 36,
    id: "createdOn",
    label: "Created On",
    type: tableColumnTypes.TEXT,
    width: "120px",
    maxWidth: "200px",
  },
  {
    id: "modifiedOn",
    columnId: 37,
    label: "Last Modified On",
    type: tableColumnTypes.TEXT,
    width: "120px",
    maxWidth: "200px",
  },
];

const manageAcceptedRequestsTableHeaderColumnsMock = [
  {
    columnId: 41,
    id: "title",
    label: "Title",
    type: tableColumnTypes.SUBJECT_ICON_NAME,
    width: "250px",
    maxWidth: "300px",
  },
  {
    columnId: 42,
    id: "subjectName",
    label: "Subject",
    type: tableColumnTypes.TEXT,
    width: "100px",
    maxWidth: "150px",
  },
  {
    columnId: 43,
    id: "grade",
    label: "Grade",
    type: tableColumnTypes.TEXT,
    width: "100px",
    maxWidth: "150px",
  },
  {
    id: "requestedBy",
    columnId: 44,
    label: "Requested By",
    type: tableColumnTypes.TEXT,
    width: "150px",
    maxWidth: "200px",
  },
  {
    columnId: 45,
    id: "requestedOn",
    label: "Requested On",
    type: tableColumnTypes.TEXT,
    width: "120px",
    maxWidth: "180px",
  },
  {
    columnId: 46,
    id: "acceptedOn",
    label: "Approved On",
    type: tableColumnTypes.TEXT,
    width: "120px",
    maxWidth: "180px",
  },
  {
    columnId: 47,
    id: "createdOn",
    label: "Created On",
    type: tableColumnTypes.TEXT,
    width: "120px",
    maxWidth: "180px",
  },
  {
    id: "modifiedOn",
    columnId: 48,
    label: "Last Modified On",
    type: tableColumnTypes.TEXT,
    width: "150px",
    maxWidth: "200px",
  },
];

const manageRequestPendingInputConfigsMock = [
  {
    id: "tenant-course-mng-manage-request-title-filter",
    name: "selectedTitleDDValue",
    fieldName: "titleDDMenuItems",
    value: "",
    menuItems: titleListMock,
    label: "Title",
    fieldType: FIELD_TYPES.SELECT,
    placeHolderText: "Select Title",
  },
  {
    fieldType: FIELD_TYPES.SELECT,
    id: "tenant-course-mng-manage-request-requestedBy-filter",
    name: "selectedRequestedByDDValue",
    placeHolderText: "Select Option",
    fieldName: "requestedByDDMenuItems",
    value: "",
    menuItems: teacherNameListMock,
    label: "Requested By",
  },
  {
    id: "tenant-course-mng-manage-request-requestedOn-filter",
    name: "selectedRequestedOnDDValue",
    fieldType: FIELD_TYPES.SELECT,
    placeHolderText: "Select Option",
    fieldName: "requestedOnDDMenuItems",
    value: "",
    menuItems: acsDescMock,
    label: "Requested On",
  },
  {
    id: "tenant-course-mng-manage-request-subject-filter",
    name: "selectedSubjectHandledDDValue",
    fieldType: FIELD_TYPES.SELECT,
    placeHolderText: "Select Subject",
    fieldName: "subjectHandledDDMenuItems",
    value: "",
    menuItems: subjectsListMock,
    label: "Subject",
  },
  {
    id: "tenant-course-mng-manage-request-gradeLevel-filter",
    name: "selectedGradeLevelDDValue",
    fieldName: "gradeLevelDDMenuItems",
    value: "",
    fieldType: FIELD_TYPES.SELECT,
    placeHolderText: "Select Grade Level",
    menuItems: gradeLevelListMock,
    label: "Grade",
  },
];

const manageRequestAcceptedInputConfigsMock = [
  {
    id: "tenant-course-mng-manage-request-title-filter",
    name: "selectedTitleDDValue",
    fieldName: "titleDDMenuItems",
    value: "",
    menuItems: titleListMock,
    label: "Title",
    fieldType: FIELD_TYPES.SELECT,
    placeHolderText: "Select Title",
  },
  {
    fieldType: FIELD_TYPES.SELECT,
    id: "tenant-course-mng-manage-request-requestedBy-filter",
    name: "selectedRequestedByDDValue",
    placeHolderText: "Select Option",
    fieldName: "requestedByDDMenuItems",
    value: "",
    menuItems: teacherNameListMock,
    label: "Requested By",
  },
  {
    id: "tenant-course-mng-manage-request-requestedOn-filter",
    name: "selectedRequestedOnDDValue",
    fieldType: FIELD_TYPES.SELECT,
    placeHolderText: "Select Option",
    fieldName: "requestedOnDDMenuItems",
    value: "",
    menuItems: acsDescMock,
    label: "Requested On",
  },
  {
    id: "tenant-course-mng-manage-request-acceptedOn-filter",
    name: "selectedAcceptedOnDDValue",
    fieldType: FIELD_TYPES.SELECT,
    placeHolderText: "Select Option",
    fieldName: "acceptedOnDDMenuItems",
    value: "",
    menuItems: acsDescMock,
    label: "Accepted On",
  },
  {
    id: "tenant-course-mng-manage-request-subject-filter",
    name: "selectedSubjectHandledDDValue",
    fieldType: FIELD_TYPES.SELECT,
    placeHolderText: "Select Subject",
    fieldName: "subjectHandledDDMenuItems",
    value: "",
    menuItems: subjectsListMock,
    label: "Subject",
  },
  {
    id: "tenant-course-mng-manage-request-gradeLevel-filter",
    name: "selectedGradeLevelDDValue",
    fieldName: "gradeLevelDDMenuItems",
    value: "",
    fieldType: FIELD_TYPES.SELECT,
    placeHolderText: "Select Grade Level",
    menuItems: gradeLevelListMock,
    label: "Grade",
  },
];

const pendingRequestsCourseDetailInputConfigMock = [
  {
    id: "tenant-pending-course-request-title-filter",
    name: "selectedTitleDDValue",
    fieldName: "topicTitleDDMenuItems",
    value: "",
    menuItems: titleListMock,
    label: "Title",
    fieldType: FIELD_TYPES.SELECT,
    placeHolderText: "Select Title",
  },
  {
    id: "tenant-pending-course-request-subject-filter",
    name: "selectedSubjectHandledDDValue",
    fieldType: FIELD_TYPES.SELECT,
    placeHolderText: "Select Subject",
    fieldName: "subjectHandledDDMenuItems",
    value: "",
    menuItems: subjectsListMock,
    label: "Subject",
  },
  {
    id: "tenant-pending-course-request-gradeLevel-filter",
    name: "selectedGradeLevelDDValue",
    fieldName: "gradeLevelDDMenuItems",
    value: "",
    fieldType: FIELD_TYPES.SELECT,
    placeHolderText: "Select Grade Level",
    menuItems: gradeLevelListMock,
    label: "Grade",
  },
  {
    fieldType: FIELD_TYPES.SELECT,
    id: "tenant-pending-course-request-noOfLessons-filter",
    name: "selectedNoOfLessonsPerDayDDValue",
    placeHolderText: "Select Count",
    fieldName: "noOfLessonsPerDayDDMenuItems",
    value: "",
    menuItems: countListMock,
    label: "No. of lessons per day",
  },
  {
    fieldType: FIELD_TYPES.SELECT,
    id: "tenant-pending-course-request-status-filter",
    name: "selectedStatusDDValue",
    placeHolderText: "Select Status",
    fieldName: "statusDDMenuItems",
    value: "",
    menuItems: statusListMock1,
    label: "Status",
  },
];

const pendingRequestCourseDetailTableHeaderColumnsMock = [
  {
    columnId: 41,
    id: "title",
    label: "Title",
    type: tableColumnTypes.TEXT,
  },
  {
    columnId: 42,
    id: "subjectName",
    label: "Subject",
    type: tableColumnTypes.TEXT,
  },
  {
    columnId: 43,
    id: "grade",
    label: "Grade",
    type: tableColumnTypes.TEXT,
  },
  {
    columnId: 47,
    id: "noOfLessonsPerDay",
    label: "No. of Lessons Per Day",
    type: tableColumnTypes.TEXT,
  },
  {
    id: "status",
    columnId: 48,
    label: "Status",
    type: tableColumnTypes.CHIP,
  },
];

const pendingRequestCoursesListMock = [
  {
    id: 1,
    title: "Topic Title 1",
    subjectName: "Science",
    grade: "Grade 3",
    noOfLessonsPerDay: 2,
    status: "Modified",
  },
  {
    id: 2,
    title: "Topic Title 2",
    subjectName: "Maths",
    grade: "Grade 3",
    noOfLessonsPerDay: 1,
    status: "New",
  },
  {
    id: 3,
    title: "Topic Title 3",
    subjectName: "History",
    grade: "Grade 3",
    noOfLessonsPerDay: 1,
    status: "New",
  },
  {
    id: 4,
    title: "Topic Title 4",
    subjectName: "English",
    grade: "Grade 3",
    noOfLessonsPerDay: 3,
    status: "Modified",
  },
  {
    id: 5,
    title: "Topic Title 5",
    subjectName: "English",
    grade: "Grade 3",
    noOfLessonsPerDay: 2,
    status: "Modified",
  },
  {
    id: 6,
    title: "Topic Title 6",
    subjectName: "English",
    grade: "Grade 3",
    noOfLessonsPerDay: 4,
    status: "New",
  },
  {
    id: 7,
    title: "Topic Title 7",
    subjectName: "Science",
    grade: "Grade 3",
    noOfLessonsPerDay: 1,
    status: "New",
  },
  {
    id: 8,
    title: "Topic Title 8",
    subjectName: "Maths",
    grade: "Grade 3",
    noOfLessonsPerDay: 2,
    status: "New",
  },
  {
    id: 9,
    title: "Topic Title 9",
    subjectName: "History",
    grade: "Grade 3",
    noOfLessonsPerDay: 3,
    status: "Modified",
  },
];

const assignCourseToCatalogInputConfigMock = [
  {
    id: "tenant-assign-course-to-catalog-courseTitle-menu",
    name: "selectedCourseTitleDDValue",
    fieldName: "courseTitleDDMenuItems",
    value: "",
    menuItems: titleListMock,
    label: "Course Title",
    fieldType: FIELD_TYPES.SELECT,
    placeHolderText: "Title",
  },
  {
    id: "tenant-assign-course-to-catalog-subject",
    name: "subject",
    fieldType: FIELD_TYPES.TEXT,
    placeHolderText: "",
    fieldName: "subject",
    value: "",
    menuItems: [],
    isDisabled: true,
    label: "Subject",
  },
  {
    id: "tenant-assign-course-to-catalog-gradeLevel",
    name: "gradeLevel",
    fieldName: "gradeLevel",
    value: "",
    fieldType: FIELD_TYPES.TEXT,
    placeHolderText: "",
    menuItems: [],
    isDisabled: true,
    label: "Grade",
  },
  {
    fieldType: FIELD_TYPES.SELECT,
    id: "tenant-assign-course-to-catalog-catalog-menu",
    name: "selectedAssignCourseToCatalogTitleDDValue",
    placeHolderText: "Catalog",
    fieldName: "assignCourseToCatalogDDMenuItems",
    value: "",
    menuItems: titleListMock,
    label: "Catalog",
  },
];

const assignCatalogToTeacherInputConfigMock = [
  {
    fieldType: FIELD_TYPES.SELECT,
    id: "tenant-assign-catalog-to-teacher-catalog-menu",
    name: "selectedCatalogTitleListDDValue",
    placeHolderText: "Select Catalog",
    fieldName: "catalogTitleListDDMenuItems",
    value: "",
    menuItems: titleListMock,
    label: "Catalog Title",
  },
];

const availableTeachersListMock = [
  {
    teacher: {
      name: "Milton Perry",
      icon: man2,
      boxWidth: "32px",
      boxHeight: "32px",
    },
    id: "1",
    curriculum: "Curriculum 1",
    catalog: "Catalog 1",
  },
  {
    teacher: {
      name: "Amalia Cabal",
      icon: man4,
      boxWidth: "32px",
      boxHeight: "32px",
    },
    id: "2",
    curriculum: "Curriculum 1",
    catalog: "Catalog 1",
  },
  {
    teacher: {
      name: "Isak Johanneson",
      icon: man5,
      boxWidth: "32px",
      boxHeight: "32px",
    },
    id: "3",
    curriculum: "Curriculum 4",
    catalog: "Catalog 1",
  },
  {
    teacher: {
      name: "Jason Bailey",
      icon: man6,
      boxWidth: "32px",
      boxHeight: "32px",
    },
    id: "4",
    curriculum: "Curriculum 4",
    catalog: "Catalog 1",
  },
  {
    teacher: {
      name: "Prescott MacCaffery",
      icon: man2,
      boxWidth: "32px",
      boxHeight: "32px",
    },
    id: "5",
    curriculum: "Curriculum 4",
    catalog: "Catalog 1",
  },
  {
    teacher: {
      name: "Vicente de la Cruz",
      icon: man4,
      boxWidth: "32px",
      boxHeight: "32px",
    },
    id: "6",
    curriculum: "Curriculum 1",
    catalog: "Catalog 1",
  },
];

const assignCatalogToTeacherTableHeaderMock = [
  {
    columnId: 31,
    id: "teacher",
    label: "Teacher",
    type: tableColumnTypes.STUDENT_ICON,
    width: "250px",
    maxWidth: "280px",
  },
  {
    columnId: 32,
    id: "curriculum",
    label: "Curriculum",
    type: tableColumnTypes.TEXT,
    width: "150px",
    maxWidth: "180px",
  },
  {
    columnId: 23,
    id: "catalog",
    label: "Catalog",
    type: tableColumnTypes.TEXT,
    width: "150px",
    maxWidth: "180px",
  },
  {
    id: "actionButton",
    columnId: 37,
    label: "",
    type: tableColumnTypes.BUTTON,
  },
];

const mockCourseData = {
	"data": [
		{
			"id": "75",
			"type": "course",
			"attributes": {
				"course_title": "Geometry ",
				"description": "Test modify no 1",
				"curriculum": {
					"id": 60,
					"name": "Curriculum 6"
				},
				"subject": {
					"id": 214,
					"name": "Math",
					"color": "#0ac2f0",
					"image": null,
					"icon": "https://hgapplicationupdatedryan-230583-ruby.b230583.dev.centralindia.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdXNCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--7a356867fca64b28fd275492ecf9ffb2adb00138/math.png",
					"filename": "math.png",
					"content_type": "image/png"
				},
				"grade": {
					"id": 56,
					"name": "Grade 2"
				},
				"status": "Draft",
				"topic_count": 5,
				"activity_count": 24,
				"no_of_lession_a_day": "1",
				"total_no_of_lessons": 9,
				"school_year": {
					"id": 94,
					"title": "2026",
					"start_date": "2026-03-04",
					"end_date": "2026-12-09"
				},
				"term": "Final exam",
				"school_id": 16,
				"short_code": "6320",
				"custom_field": {
					"id": 1,
					"label": "address",
					"custom_field_type": null
				},
				"updated_course": null,
				"modified_count": 9,
				"created_at": "2024-03-11T04:51:13.714Z",
				"updated_at": "2024-06-07T07:51:40.940Z",
				"is_assigned": null,
				"is_disabled": null,
				"completed_lessons": 4,
				"participants_count": 9,
				"teacher_course_status": null,
				"student_course_status": null,
				"course_completion": 100,
				"completion_score_by_student": 0,
				"class_courses": [
					{
						"id": 18,
						"course_id": 75,
						"class_management_id": 59,
						"teacher_id": 822,
						"created_at": "2024-05-23T06:57:36.795Z",
						"updated_at": "2024-05-23T06:57:36.795Z"
					},
					{
						"id": 79,
						"course_id": 75,
						"class_management_id": 79,
						"teacher_id": 963,
						"created_at": "2024-09-25T08:14:09.927Z",
						"updated_at": "2024-09-25T08:14:09.927Z"
					}
				],
				"user": {
					"id": 203,
					"first_name": "Demo",
					"last_name": "Test",
					"role": "School Admin"
				},
				"modified_by": {
					"id": 203,
					"first_name": "Demo",
					"last_name": "Test",
					"role": "School Admin"
				}
			}
		}
	],
	"meta": {
		"pagination": {
			"per_page": 10,
			"current_page": 1,
			"next_page": null,
			"prev_page": null,
			"total_pages": 1,
			"total_count": 1
		}
	}
}

const mockCatalogData = {
	"data": [
		{
			"id": "90",
			"type": "catalogue",
			"attributes": {
				"name": "Test Catalog",
				"description": null,
				"school_id": 16,
				"created_by": {
					"id": 203,
					"first_name": "Demo",
					"last_name": "Test"
				},
				"curriculum": {
					"id": 90,
					"name": "New Curriculum "
				},
				"grade": {
					"id": 91,
					"name": "New grade"
				},
				"school_year": {
					"id": 92,
					"title": "2024"
				},
				"custom_fields": {
					"data": []
				},
				"courses": [
					{
						"id": 77,
						"course_title": "Physics "
					},
					{
						"id": 78,
						"course_title": "Roman History"
					},
					{
						"id": 274,
						"course_title": "Book"
					}
				],
				"teacher_catalogues": {
					"data": [
						{
							"id": "8",
							"type": "teacher_catalogue",
							"attributes": {
								"catalogue_id": 90,
								"created_at": "2024-05-06T04:49:57.708Z",
								"updated_at": "2024-05-06T04:49:57.708Z",
								"teacher": {
									"id": 824,
									"email": "testteacher12332111@yopmail.com",
									"first_name": "Test Teacher",
									"last_name": "Demo",
									"profile_pic": "https://hgapplicationupdatedryan-230583-ruby.b230583.dev.centralindia.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBblVEIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--71945da2aedddf09af8df5de694b5c0ce672cabf/sub.jpeg"
								}
							}
						},
						{
							"id": "14",
							"type": "teacher_catalogue",
							"attributes": {
								"catalogue_id": 90,
								"created_at": "2024-05-06T10:01:11.343Z",
								"updated_at": "2024-05-06T10:01:11.343Z",
								"teacher": {
									"id": 822,
									"email": "teacheryash@yopmail.com",
									"first_name": "Yash",
									"last_name": "Master",
									"profile_pic": "https://hgapplicationupdatedryan-230583-ruby.b230583.dev.centralindia.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBb2NFIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--dceda0e19efa69fcca648885706e3c77ef8d1ddb/images%20(1).jpeg"
								}
							}
						}
					]
				}
			}
		},
		{
			"id": "138",
			"type": "catalogue",
			"attributes": {
				"name": "Test Catalog",
				"description": null,
				"school_id": 16,
				"created_by": {
					"id": 203,
					"first_name": "Demo",
					"last_name": "Test"
				},
				"curriculum": {
					"id": 90,
					"name": "New Curriculum "
				},
				"grade": {
					"id": 91,
					"name": "New grade"
				},
				"school_year": {
					"id": 92,
					"title": "2024"
				},
				"custom_fields": {
					"data": []
				},
				"courses": [
					{
						"id": 77,
						"course_title": "Physics "
					},
					{
						"id": 78,
						"course_title": "Roman History"
					},
					{
						"id": 274,
						"course_title": "Book"
					}
				],
				"teacher_catalogues": {
					"data": [
						{
							"id": "53",
							"type": "teacher_catalogue",
							"attributes": {
								"catalogue_id": 138,
								"created_at": "2024-09-18T13:19:50.528Z",
								"updated_at": "2024-09-18T13:19:50.528Z",
								"teacher": {
									"id": 999,
									"email": "mentormerry@yopmail.com",
									"first_name": "Mentor",
									"last_name": "Merry",
									"profile_pic": "https://hgapplicationupdatedryan-230583-ruby.b230583.dev.centralindia.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBbE1GIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--a5642770504893a8e8c61fbf513a09cd7a24cd07/Parent%20Teacher.jpg"
								}
							}
						},
						{
							"id": "61",
							"type": "teacher_catalogue",
							"attributes": {
								"catalogue_id": 138,
								"created_at": "2024-09-25T11:16:14.912Z",
								"updated_at": "2024-09-25T11:16:14.912Z",
								"teacher": {
									"id": 948,
									"email": "rubybe@yopmail.com",
									"first_name": "Ruby",
									"last_name": "BE",
									"profile_pic": "https://hgapplicationupdatedryan-230583-ruby.b230583.dev.centralindia.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdXNFIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--592277c38fefa4dc97c43696f91971ac39e1b383/Teacher.jpeg"
								}
							}
						}
					]
				}
			}
		}
	],
	"meta": {
		"pagination": {
			"per_page": 10,
			"current_page": 1,
			"next_page": null,
			"prev_page": null,
			"total_pages": 1,
			"total_count": 2
		}
	}
}

export {
  curriculumListMock,
  gradeLevelListMock,
  subjectsListMock,
  teacherNameListMock,
  customField1ListMock,
  customField2ListMock,
  schoolYearListMock,
  courseUtilizationListMock,
  titleListMock,
  availableCoursesListMock,
  tenantCatalogCreationStep2InputConfigMock,
  tenantCatalogCreationStep2TableHeaderMock,
  catalogTableBodyDataMock,
  teacherCoursesTableBodyDataMock,
  coursesTableHeaderColumnsMock,
  acsDescMock,
  manageRequestAcceptedInputConfigsMock,
  manageRequestPendingInputConfigsMock,
  manageAcceptedRequestsTableHeaderColumnsMock,
  managePendingRequestsTableHeaderColumnsMock,
  countListMock,
  statusListMock1,
  pendingRequestsCourseDetailInputConfigMock,
  pendingRequestCourseDetailTableHeaderColumnsMock,
  pendingRequestCoursesListMock,
  assignCourseToCatalogInputConfigMock,
  availableTeachersListMock,
  assignCatalogToTeacherInputConfigMock,
  assignCatalogToTeacherTableHeaderMock,
  mockCourseData,
  mockCatalogData
};
