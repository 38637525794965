// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';

import { ToastContainer } from 'react-toastify';
import {
  withRouter,
  BrowserRouter as Router,
  Switch,
  Redirect
} from 'react-router-dom';
import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import TopNav from '../../components/src/TopNav';
import HomeScreen from '../../components/src/HomeScreen';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import Customisableuserprofiles2 from '../../blocks/customisableuserprofiles2/src/Customisableuserprofiles2';
import Cftexteditor6 from '../../blocks/cftexteditor6/src/Cftexteditor6';
import Timetracker2 from '../../blocks/timetracker2/src/Timetracker2';
import Tasks from '../../blocks/tasks/src/Tasks';
import TaskWeb from '../../blocks/tasks/src/Task.web';
import TaskList from '../../blocks/tasks/src/TaskList';
import Task from '../../blocks/tasks/src/Task';
import Cfgradingsystem2 from '../../blocks/cfgradingsystem2/src/Cfgradingsystem2';
import Cflti13integration from '../../blocks/cflti13integration/src/Cflti13integration';
import Agentdashboard2 from '../../blocks/agentdashboard2/src/Agentdashboard2';
import Cfsorting1 from '../../blocks/cfsorting1/src/Cfsorting1';
import Filteritems from '../../blocks/filteritems/src/Filteritems';
import Filteroptions from '../../blocks/filteritems/src/Filteroptions';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/PhoneNumberInput';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/AdditionalDetailForm';
import EducationalUserProfile from '../../blocks/educational-user-profile/src/EducationalUserProfile';
import ThemeBlock from '../../blocks/themeblock/src/ThemeBlock';
import Cfreportcard from '../../blocks/cfreportcard/src/Cfreportcard';
import Dynamiccontent2 from '../../blocks/dynamiccontent2/src/Dynamiccontent2';
import AdvancedSearch from '../../blocks/advancedsearch/src/AdvancedSearch';
import Emailnotifications2 from '../../blocks/emailnotifications2/src/Emailnotifications2';
import Customform3 from '../../blocks/customform3/src/Customform3';
import Cfviewreportcardofficialandunofficial from '../../blocks/cfviewreportcardofficialandunofficial/src/Cfviewreportcardofficialandunofficial';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import Documentstorage from '../../blocks/documentstorage/src/Documentstorage';
import Cfcreateactivity2 from '../../blocks/cfcreateactivity2/src/Cfcreateactivity2';
import Contentmanagement3 from '../../blocks/contentmanagement3/src/Contentmanagement3';
import InvertColour from '../../blocks/invertcolour/src/InvertColour';
import Cfcolorindicators5 from '../../blocks/cfcolorindicators5/src/Cfcolorindicators5';
import Cfcustomfields3 from '../../blocks/cfcustomfields3/src/Cfcustomfields3';
import Assessmenttest from '../../blocks/assessmenttest/src/Assessmenttest';
import Cfresumejourney from '../../blocks/cfresumejourney/src/Cfresumejourney';
import VisualAnalytics from '../../blocks/visualanalytics/src/VisualAnalytics';
import Adminconsole2 from '../../blocks/adminconsole2/src/Adminconsole2';
import Cfadvancesearchsort from '../../blocks/cfadvancesearchsort/src/Cfadvancesearchsort';
import Notifications from '../../blocks/notifications/src/Notifications';
import Smartcategorisation from '../../blocks/smartcategorisation/src/Smartcategorisation';
import Cftemplateforlessons from '../../blocks/cftemplateforlessons/src/Cftemplateforlessons';
import Download from '../../blocks/download/src/Download';
import Cfthresholdforgrades2 from '../../blocks/cfthresholdforgrades2/src/Cfthresholdforgrades2';
import Audittrail2 from '../../blocks/audittrail2/src/Audittrail2';
import Questionbank from '../../blocks/questionbank/src/Questionbank';
import Reviewprompt2 from '../../blocks/reviewprompt2/src/Reviewprompt2';
import UserStatus from '../../blocks/userstatus/src/UserStatus';
import Imagethumbnail from '../../blocks/imagethumbnail/src/Imagethumbnail';
import Realtimeupdates2 from '../../blocks/realtimeupdates2/src/Realtimeupdates2';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import Notes2 from '../../blocks/notes2/src/Notes2';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import UserProfileBasicBlock from '../../blocks/user-profile-basic/src/UserProfileBasicBlock';
import Cfparentdashboard from '../../blocks/cfparentdashboard/src/Cfparentdashboard';
import Pushnotifications from '../../blocks/pushnotifications/src/Pushnotifications';
import AccountGroups from '../../blocks/accountgroups/src/AccountGroups';
import Accountscoreranking from '../../blocks/accountscoreranking/src/Accountscoreranking';
import Scheduling from '../../blocks/scheduling/src/Scheduling';
import Contactus from '../../blocks/contactus/src/Contactus';
import AddContactus from '../../blocks/contactus/src/AddContactus';
import Cfcoursemanagement from '../../blocks/cfcoursemanagement/src/Cfcoursemanagement';
import Settings2 from '../../blocks/settings2/src/Settings2';
import Rolesandpermissions from '../../blocks/rolesandpermissions/src/Rolesandpermissions';
import Timeclock from '../../blocks/timeclock/src/Timeclock';
import Cfadditionalassessmenttest2 from '../../blocks/cfadditionalassessmenttest2/src/Cfadditionalassessmenttest2';
import Print from '../../blocks/print/src/Print';
import CreateComment from '../../blocks/comments/src/CreateComment';
import Sorting from '../../blocks/sorting/src/Sorting';
import PostCreation from '../../blocks/postcreation/src/PostCreation';
import Posts from '../../blocks/postcreation/src/Posts';
import PostDetails from '../../blocks/postcreation/src/PostDetails';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import Search from '../../blocks/search/src/Search';
import Videolibrary2 from '../../blocks/videolibrary2/src/Videolibrary2';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock.web';
import Scoring from '../../blocks/scoring/src/Scoring';
import Videos from '../../blocks/videos/src/Videos';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import Dashboard from '../../blocks/dashboard/src/Dashboard';
import ImportExportData from '../../blocks/importexportdata/src/ImportExportData';
import Cfgradingcomprules2 from '../../blocks/cfgradingcomprules2/src/Cfgradingcomprules2';
import Matchalgorithm4 from '../../blocks/matchalgorithm4/src/Matchalgorithm4';
import Profilebio from '../../blocks/profilebio/src/Profilebio';
import TermsConditions from '../../blocks/termsconditions/src/TermsConditions';
import TermsConditionsDetail from '../../blocks/termsconditions/src/TermsConditionsDetail';
import TermsConditionsUsers from '../../blocks/termsconditions/src/TermsConditionsUsers';
import Projecttemplates from '../../blocks/projecttemplates/src/Projecttemplates';
import BulkUploading from '../../blocks/bulkuploading/src/BulkUploading';
import Uploadmedia3 from '../../blocks/uploadmedia3/src/Uploadmedia3';
import LandingPage from '../../blocks/landingpage/src/LandingPage';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import Cfcurriculmschoolyearcreation2 from '../../blocks/cfcurriculmschoolyearcreation2/src/Cfcurriculmschoolyearcreation2';
import PerformanceTracker from '../../blocks/performancetracker/src/PerformanceTracker';
import PerformanceTrackerCoursesScreen from '../../blocks/performancetracker/src/PerformanceTrackerCoursesScreen';
import PerformanceTrackerTestsForCourseScreen from '../../blocks/performancetracker/src/PerformanceTrackerTestsForCourseScreen';
import PerformanceTrackerStudentsOnCourseScreen from '../../blocks/performancetracker/src/PerformanceTrackerStudentsOnCourseScreen';
import PerformanceTrackerTestScoresForStudentScreen from '../../blocks/performancetracker/src/PerformanceTrackerTestScoresForStudentScreen';
import PerformanceTrackerBasicUserCoursesScreen from '../../blocks/performancetracker/src/PerformanceTrackerBasicUserCoursesScreen';
import PerformanceTrackerBasicUserAllCoursesScreen from '../../blocks/performancetracker/src/PerformanceTrackerBasicUserAllCoursesScreen';
import PerformanceTrackerStudentRankingScreen from '../../blocks/performancetracker/src/PerformanceTrackerStudentRankingScreen';
import PerformanceTrackerStudentInfoScreen from '../../blocks/performancetracker/src/PerformanceTrackerStudentInfoScreen';
import Analytics from '../../blocks/analytics/src/Analytics';
import PhotoLibrary from '../../blocks/photolibrary/src/PhotoLibrary';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import Fileattachment from '../../blocks/fileattachment/src/Fileattachment';
import Cfcurriculmschoolyearcreation22 from '../../blocks/cfcurriculmschoolyearcreation22/src/Cfcurriculmschoolyearcreation22';
import ReviewApprovalAdmin from '../../blocks/reviewandapproval/src/ReviewApprovalAdmin';
import ReviewApprovalBasicUser from '../../blocks/reviewandapproval/src/ReviewApprovalBasicUser';
import Smartcategorisations from '../../blocks/smartcategorisation/src/Smartcategorisation.web';

import CreateNewPassword from '../../blocks/forgot-password/src/CreateNewPassword.web';
import StudentDashboard from '../../blocks/dashboard/src/StudentDashboard.web';
import StudentMyCourses from '../../blocks/cfcoursemanagement/src/StudentMyCourses.web';
import StudentMyCoursesSubjectDetails from '../../blocks/cfcoursemanagement/src/StudentMyCoursesSubjectDetails.web';
import PageNotFound from '../../components/src/CustomPageNotFound.web';
import StudentMyCoursesActivityDetails from '../../blocks/cfcoursemanagement/src/StudentMyCoursesActivityDetails.web';
import StudentDueAssessmentMain from '../../blocks/cfcoursemanagement/src/StudentDueAssessmentMain.web';
import StudentCompletedAssessmentMain from '../../blocks/cfcoursemanagement/src/StudentCompletedAssessmentMain.web';
import StudentCompletedQuizMain from '../../blocks/cfcoursemanagement/src/StudentCompletedQuizMain.web';
import StudentMyAssessments from './../../blocks/cfcoursemanagement/src/StudentMyAssessments.web';
import StudentMyPath from './../../blocks/cfcoursemanagement/src/StudentMyPath.web';
import StudentMyGradebook from './../../blocks/cfcoursemanagement/src/StudentMyGradebook.web';
import AccountLogin from '../../blocks/email-account-login/src/AccountLogin.web';
import ForgotAccountPassword from '../../blocks/forgot-password/src/ForgotAccountPassword.web';
import OTPAuth from '../../blocks/otp-input-confirmation/src/OTPAuth.web';
import TenantDashboardMain from '../../blocks/dashboard/src/TenantDashboardMain.web';
import TenantAdminSetupDashboard from '../../blocks/dashboard/src/TenantAdminSetupDashboard.web';
import TenantUserManagement from '../../blocks/userstatus/src/TenantUserManagement.web';
import CourseManagementMain from '../../blocks/cfcoursemanagement/src/CourseManagementMain.web';
import TenantCourseManagementManageRequestMain from '../../blocks/cfcoursemanagement/src/TenantCourseManagementManageRequestMain.web';
import TenantCourseManagementPendingRequestDetail from '../../blocks/cfcoursemanagement/src/TenantCourseManagementPendingRequestDetail.web';
import AddNewUserDetails from '../../blocks/userstatus/src/AddNewUserDetails.web';
import AddNewStudentDetails from '../../blocks/userstatus/src/AddNewStudentDetails.web';
import ViewStudentProfileDetails from '../../blocks/userstatus/src/ViewStudentProfileDetails.web';
import ViewParentTeacherProfileDetails from '../../blocks/userstatus/src/ViewParentTeacherProfileDetails.web';
import ViewUserProfileDetails from '../../blocks/userstatus/src/ViewUserProfileDetails.web';
import AppearanceManagementMain from '../../blocks/themeblock/src/AppearanceManagementMain.web';
import TenantRolesAndPermissions from '../../blocks/rolesandpermissions/src/TenantRolesAndPermissions.web';
import AddEditTenantRolesAndPermissions from '../../blocks/rolesandpermissions/src/AddEditTenantRolesAndPermissions.web';
import CourseManagementDetails from './../../blocks/cfcoursemanagement/src/CourseManagementDetails.web';
import TeacherDashboard from '../../blocks/dashboard/src/TeacherDashboard.web';
import ParentTeacherDashboard from '../../blocks/cfparentdashboard/src/parentteacherdashboard/ParentTeacherDashboard.web';
import StudentTeacher from '../../blocks/cfcoursemanagement/src/TeacherDashboardStudent.web';
import StudentProfileProgress from '../../blocks/cfcoursemanagement/src/StudentProfileProgress.web';
import StudentProgresDetails from '../../blocks/cfcoursemanagement/src/StudentProgressDetails.web';
import StudentPage from '../../blocks/cfparentdashboard/src/studentPage/studentPage.web';
import CourseManagement from '../../blocks/cfcoursemanagement/src/parentteacher/CourseManagement.web';
import StudentProfile from '../../blocks/user-profile-basic/src/StudentProfile.web';
import TeacherProfile from '../../blocks/user-profile-basic/src/TeacherProfile.web';
import ChangePassword from '../../blocks/forgot-password/src/ChangePassword.web';
import ManageEvents from '../../blocks/cfcurriculmschoolyearcreation2/src/ManageEvents.web';
import StudentPerformanceAssessment from '../../blocks/assessmenttest/src/StudentPerformanceAssessment.web';
import CourseDetailPage from '../../blocks/cfcoursemanagement/src/parentteacher/CourseDetailPage.web';
import TeacherCalReshedulling from '../../blocks/cfresumejourney/src/TeacherCalReshedulling.web';
import MyRequestsPage from '../../blocks/cfcoursemanagement/src/parentteacher/MyRequestsPage.web';
import CoursesPage from '../../blocks/cfcoursemanagement/src/CoursesPage.web';
import JourneyCourseActivityDetail from '../../blocks/cfcoursemanagement/src/JourneyCourseActivityDetail.web';
import CourseManagementParent from '../../blocks/cfcoursemanagement/src/TeacherCourseManagementParent.web';
import TeacherMyRequest from '../../blocks/cfcoursemanagement/src/TeacherMyRequest.web';
import TeacherCoursesMain from '../../blocks/cfcoursemanagement/src/TeacherCoursesMain.web';
import 'react-toastify/dist/ReactToastify.css';
import ParenTeacherGradebook from '../../blocks/cfparentdashboard/src/ParenTeacherGradebook.web';
import GradeUserDetailPage from '../../blocks/cfparentdashboard/src/GradeUserDetailPage.web';
import ParentTeacherAssessments from '../../blocks/assessmenttest/src/ParentTeacherAssessments.web';
import ParentTeacherCalendar from '../../blocks/cfresumejourney/src/ParentTeacherCalendar.web';
import AssessmentDetailPage from '../../blocks/assessmenttest/src/AssessmentDetailPage.web';
import TeacherGradebook from '../../blocks/cfthresholdforgrades2/src/TeacherGradebook.web';
import CourseActivityJourneyDetails from '../../blocks/cfcoursemanagement/src/StudentCourseActivityJourneyDetails.web';
import RegistrarStudentsList from '../../blocks/customisableuserprofiles2/src/RegistrarStudentsList.web';
import RegistrarProfile from '../../blocks/user-profile-basic/src/RegistrarProfile.web';
import GradebookGradesTab from '../../blocks/cfparentdashboard/src/GradebookGradesTab.web';
import EditProfile from '../../blocks/user-profile-basic/src/EditProfile.web';
import CustomLoader from '../../components/src/CustomLoader.web';
import ParentTeacherProfile from '../../blocks/user-profile-basic/src/ParentTeacherProfile.web';
import StudentProfileAssignedActivitiesList from "../../blocks/cfcoursemanagement/src/StudentProfileAssignedActivitiesList.web"
import GradebookDetailsPage from '../../components/src/gradebookDetails/GradebookDetailPage.web'
import NewBehaviourWeb from '../../blocks/dashboard/src/NewBehaviour.web';
import  GradeBookTemplateOrientation  from '../../blocks/dashboard/src/gradebookTemplate/GradeBookTemplateOrientation.web';
import AssessmentTestProvider from '../../blocks/assessmenttest/src/context/AssessmentTestProvider';
import ParentTeacherDashboardViewall from '../../blocks/cfparentdashboard/src/parentteacherdashboard/ParentTeacherDashboardViewall.web';
import ParentAssinedAssesment from '../../blocks/cfparentdashboard/src/parentteacherdashboard/ParentAssinedAssesment.web';
const routeMap = {
  AccountLogin: {
    component: AccountLogin,
    path: '/AccountLogin',
    type: 'Guest'
  },
  ForgotPassword: {
    component: ForgotAccountPassword,
    path: '/ForgotPassword',
    type: 'Guest'
  },
  OTPAuth: {
    component: OTPAuth,
    path: '/OTPAuthentication',
    type: 'Guest'
  },
  StudentProfile: {
    component: StudentProfile,
    path: '/Profile'
  },
  EditProfileTeacher: {
    component: EditProfile,
    path: '/TeacherDashboard/EditProfile'
  },
  TeacherProfile: {
    component: TeacherProfile,
    path: '/TeacherDashboard/Profile'
  },
  ParentTeacherProfile: {
    component: ParentTeacherProfile,
    path: '/ParentTeacherProfile'
  },
  StudentChangePassword: {
    component: ChangePassword,
    path: '/ChangePassword'
  },
  EditProfile: {
    component: EditProfile,
    path: '/EditProfile'
  },
  StudentDashboard: {
    component: StudentDashboard,
    path: '/StudentDashboard'
  },
  TeacherStudentCourseJourney: {
    component: CourseManagementDetails,
    path: '/StudentTeacher/Journey/:studentId/:studentName/:selfCourse/:courseName/:courseId'
  },
  StudentTeacher: {
    component: StudentTeacher,
    path: '/StudentTeacher'
  },
  ParentStudent: {
    component: StudentTeacher,
    path: '/ParentStudent'
  },
  StudentProfileProgress: {
    component: StudentProfileProgress,
    path: '/Student/Profile-Progress/:studentId'
  },
  StudentProgresDetails: {
    component: StudentProgresDetails,
    path: '/CourseManagementParent/Profile-Details'
  },
  AssessmentStudentView: {
    component: AssessmentDetailPage,
    path: '/Assessment/:assessmentName/:assessmentId/:studentName/:studentId'
  },
  StudentPerformanceAssessment: {
    component: StudentPerformanceAssessment,
    path: '/Assessment'
  },
  TeacherCalReshedulling: {
    component: TeacherCalReshedulling,
    path: '/TeacherCalendar'
  },
  CourseManagementParent: {
    component: CourseManagementParent,
    path: '/CourseManagementParent/MyCourse'
  },
  TeacherMyRequest: {
    component: TeacherMyRequest,
    path: '/CourseManagementParent/TeacherMyRequest'
  },
  CoursesMain: {
    component: TeacherCoursesMain,
    path: '/MyCourses'
  },
  CourseActivityJourneyDetails: {
    component: CourseActivityJourneyDetails,
    path: '/TeacherCoursesMain/ActivityJourneyDetails'
  },
  StudentMyCoursesSubjectDetails: {
    component: StudentMyCoursesSubjectDetails,
    path: '/Student/my-courses-details/:subjectName/:subjectId'
  },
  StudentMyCourses: {
    component: StudentMyCourses,
    path: '/Student/my-courses'
  },
  StudentMyCoursesActivityDetails: {
    component: StudentMyCoursesActivityDetails,
    path: '/Student/my-courses-activity-details'
  },
  StudentDueAssessmentMain: {
    component: StudentDueAssessmentMain,
    path: '/Student/my-assessments/:typeOfAssessment/:evaluationType/:objectiveType/:title/:activityId'
  },
  StudentCompletedAssessmentMain: {
    component: StudentCompletedAssessmentMain,
    path:
      '/Student/submitted/my-assessments/:typeOfAssessment/:title/:assessmentCategory'
  },
  StudentGradedAssessmentMain: {
    component: StudentCompletedAssessmentMain,
    path:
      '/Student/graded/my-assessments/:typeOfAssessment/:title/:assessmentCategory'
  },
  StudentRemarkedAssessmentMain: {
    component: StudentCompletedAssessmentMain,
    path:
      '/Student/remarked/my-assessments/:typeOfAssessment/:title/:assessmentCategory/:fromStatistics?'
  },
  StudentMyAssessments: {
    component: StudentMyAssessments,
    path: '/Student/my-assessments'
  },
  StudentCompletedQuizMain: {
    component: StudentCompletedQuizMain,
    path: '/Student/quiz/my-assessments/:category/:title'
  },
  StudentMyPath: {
    component: StudentMyPath,
    path: '/Student/my-path'
  },
  StudentMyGradebook: {
    component: StudentMyGradebook,
    path: '/Student/my-gradebook'
  },
  PageNotFound: {
    component: PageNotFound,
    path: '/PageNotFound',
    type: 'Guest'
  },

  TenantDashboardMain: {
    component: TenantDashboardMain,
    path: '/TenantDashboard'
  },
  TenantAdminSetupDashboard: {
    component: TenantAdminSetupDashboard,
    path: '/TenantAdminSetupDashboard'
  },
  TenantAdminSetupAddNewBehaviour: {
    component: NewBehaviourWeb,
    path: '/TenantAdminSetup/add-new-behaviour/:title/:gradebookId'
  },
  TenantAdminSetupGradeBookTemplate: {
    component: GradeBookTemplateOrientation,
    path: '/TenantAdminSetup/template/:gradebookId'
  },
  TenantAdminSetupAddNewBehaviourById: {
    component: NewBehaviourWeb,
    path: '/TenantAdminSetup/edit-behaviour/:title/:gradebookId/:behaviourId'
  },
  TenantUserManagement: {
    component: TenantUserManagement,
    path: '/Tenant/user-management'
  },
  TenantUserManagementAddNewUserDetails: {
    component: AddNewUserDetails,
    path: '/Tenant/UserManagement/add-new-user/:role'
  },
  AddNewStudentDetails: {
    component: AddNewStudentDetails,
    path: '/Tenant/UserManagement/Student/add-new-user'
  },
  ViewParentTeacherProfileDetails: {
    component: ViewParentTeacherProfileDetails,
    path: '/Tenant/UserManagement-ViewParentTeacherProfile/:role/:fullName'
  },
  ViewUserProfileDetails: {
    component: ViewUserProfileDetails,
    path: '/Tenant/UserManagement-ViewProfile/:role/:fullName'
  },
  ViewStudentProfileDetails: {
    component: ViewStudentProfileDetails,
    path: '/Tenant/UserManagement-ViewStudentProfile/:studentId'
  },
  CourseManagementPendingRequestDetail: {
    component: CourseManagementDetails,
    path: '/CourseManagement/:id/:requestType/:selfCourse/:courseName/:courseId'
  },
  CourseManagementDetails: {
    component: CourseManagementDetails,
    path: '/CourseManagement/:selfCourse/:courseName/:courseId'
  },
  CourseManagementManageRequestMain: {
    component: TenantCourseManagementManageRequestMain,
    path: '/CourseManagement/ManageRequests',
  },
  CourseManagementMain: {
    component: CourseManagementMain,
    path: '/CourseManagement'
  },
  TenantAppearanceManagement: {
    component: AppearanceManagementMain,
    path: '/Tenant/AppearanceManagement'
  },
  TenantRolesAndPermissions: {
    component: TenantRolesAndPermissions,
    path: '/TenantRolesAndPermissions'
  },
  AddNewTenantRolesAndPermissions: {
    component: AddEditTenantRolesAndPermissions,
    path: '/AddNewTenantRolesAndPermissions'
  },
  EditTenantRolesAndPermissions: {
    component: AddEditTenantRolesAndPermissions,
    path: '/EditTenantRolesAndPermissions/:roleId'
  },
  ViewTenantRolesAndPermissions: {
    component: AddEditTenantRolesAndPermissions,
    path: '/ViewTenantRolesAndPermissions/:roleId'
  },
  RegistrarStudentsList: {
    component: RegistrarStudentsList,
    path: '/Registrar/StudentsList'
  },
  RegistrarStudentCourseProgressDetails: {
    component: StudentMyCoursesSubjectDetails,
    path: '/Registrar/Student/Course-Details/:studentId/:studentName/:subjectName/:subjectId'
  },
  RegistrarStudentsDetails: {
    component: StudentProfileProgress,
    path: '/Registrar/Students/Details/:studentId'
  },
  RegistrarGradebook: {
    component: TeacherGradebook,
    path: '/Registrar/Gradebook'
  },
  RegistrarGradebookStudentDetails: {
    component: GradebookDetailsPage,
    path: '/Registrar/GradebookStudentGradesDetail'
  },
  RegistrarStudentPerformanceAssessment: {
    component: StudentPerformanceAssessment,
    path: '/Registrar/StudentsPerformanceGraded/:studentName/:classId'
  },
  TeacherStudentPerformanceAssessment: {
    component: StudentPerformanceAssessment,
    path: '/Teacher/Assessments/:studentName/:classId'
  },
  ParentTeacherStudentPerformanceAssessment: {
    component: StudentPerformanceAssessment,
    path: '/ParentTeacher/Assessments/:studentName/:classId'
  },
  RegistrarProfile: {
    component: RegistrarProfile,
    path: '/Registrar/Profile'
  },
  EditProfileRegistrar: {
    component: EditProfile,
    path: '/Registrar/EditProfile'
  },
  RegistrarChangePassword: {
    component: ChangePassword,
    path: '/Registrar/ChangePassword'
  },
  StudentProfileAssignedActivitiesList: {
    component: StudentProfileAssignedActivitiesList,
    path: '/Students/ActivitiesList/:studentId/:activityListType'
  },
  ManageEvents: {
    component: ManageEvents,
    path: '/Setup/SchoolYear/ManageEvents'
  },
  TeacherDashboard: {
    component: TeacherDashboard,
    path: '/TeacherDashboard'
  },
  ParentDashboard: {
    component: ParentTeacherDashboard,
    path: '/ParentDashboard'
  },
  TeacherGradebook: {
    component: TeacherGradebook,
    path: '/Teacher/Gradebook'
  },
  GradebookDetails:{
    component: GradebookDetailsPage,
    path: '/Teacher/GradebookDetails'
  },
  Customisableuserprofiles2: {
    component: Customisableuserprofiles2,
    path: '/Customisableuserprofiles2'
  },
  Cftexteditor6: {
    component: Cftexteditor6,
    path: '/Cftexteditor6'
  },
  Timetracker2: {
    component: Timetracker2,
    path: '/Timetracker2'
  },
  Tasks: {
    component: Tasks,
    path: '/Tasks'
  },
  TaskList: {
    component: TaskList,
    path: '/TaskList'
  },
  Task: {
    component: Task,
    path: '/Task',
    type: 'Guest'
  },
  Cfgradingsystem2: {
    component: Cfgradingsystem2,
    path: '/Cfgradingsystem2'
  },
  Cflti13integration: {
    component: Cflti13integration,
    path: '/Cflti13integration'
  },
  Agentdashboard2: {
    component: Agentdashboard2,
    path: '/Agentdashboard2'
  },
  Cfsorting1: {
    component: Cfsorting1,
    path: '/Cfsorting1'
  },
  Filteritems: {
    component: Filteritems,
    path: '/Filteritems'
  },
  Filteroptions: {
    component: Filteroptions,
    path: '/Filteroptions'
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput'
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm'
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: '/EducationalUserProfile'
  },
  ThemeBlock: {
    component: ThemeBlock,
    path: '/ThemeBlock'
  },
  Cfreportcard: {
    component: Cfreportcard,
    path: '/Cfreportcard'
  },
  Dynamiccontent2: {
    component: Dynamiccontent2,
    path: '/Dynamiccontent2'
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: '/AdvancedSearch'
  },
  Emailnotifications2: {
    component: Emailnotifications2,
    path: '/Emailnotifications2'
  },
  Customform3: {
    component: Customform3,
    path: '/Customform3'
  },
  Cfviewreportcardofficialandunofficial: {
    component: Cfviewreportcardofficialandunofficial,
    path: '/Cfviewreportcardofficialandunofficial'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  Documentstorage: {
    component: Documentstorage,
    path: '/Documentstorage'
  },
  Cfcreateactivity2: {
    component: Cfcreateactivity2,
    path: '/Cfcreateactivity2'
  },
  Contentmanagement3: {
    component: Contentmanagement3,
    path: '/Contentmanagement3'
  },
  InvertColour: {
    component: InvertColour,
    path: '/InvertColour'
  },
  Cfcolorindicators5: {
    component: Cfcolorindicators5,
    path: '/Cfcolorindicators5'
  },
  Cfcustomfields3: {
    component: Cfcustomfields3,
    path: '/Cfcustomfields3'
  },
  Assessmenttest: {
    component: Assessmenttest,
    path: '/Assessmenttest'
  },
  Cfresumejourney: {
    component: Cfresumejourney,
    path: '/Cfresumejourney'
  },
  VisualAnalytics: {
    component: VisualAnalytics,
    path: '/VisualAnalytics'
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: '/Adminconsole2'
  },
  Cfadvancesearchsort: {
    component: Cfadvancesearchsort,
    path: '/Cfadvancesearchsort'
  },
  Notifications: {
    component: Notifications,
    path: '/Notifications'
  },
  Smartcategorisation: {
    component: Smartcategorisation,
    path: '/Smartcategorisation'
  },
  Cftemplateforlessons: {
    component: Cftemplateforlessons,
    path: '/Cftemplateforlessons'
  },
  Download: {
    component: Download,
    path: '/Download'
  },
  Cfthresholdforgrades2: {
    component: Cfthresholdforgrades2,
    path: '/Cfthresholdforgrades2'
  },
  Audittrail2: {
    component: Audittrail2,
    path: '/Audittrail2'
  },
  Questionbank: {
    component: Questionbank,
    path: '/Questionbank'
  },
  Reviewprompt2: {
    component: Reviewprompt2,
    path: '/Reviewprompt2'
  },
  UserStatus: {
    component: UserStatus,
    path: '/UserStatus'
  },
  Imagethumbnail: {
    component: Imagethumbnail,
    path: '/Imagethumbnail'
  },
  Realtimeupdates2: {
    component: Realtimeupdates2,
    path: '/Realtimeupdates2'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  Notes2: {
    component: Notes2,
    path: '/Notes2'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: '/UserProfileBasicBlock'
  },
  Cfparentdashboard: {
    component: Cfparentdashboard,
    path: '/Cfparentdashboard'
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: '/Pushnotifications'
  },
  AccountGroups: {
    component: AccountGroups,
    path: '/AccountGroups'
  },
  Accountscoreranking: {
    component: Accountscoreranking,
    path: '/Accountscoreranking'
  },
  Scheduling: {
    component: Scheduling,
    path: '/Scheduling'
  },
  Contactus: {
    component: Contactus,
    path: '/Contactus'
  },
  AddContactus: {
    component: AddContactus,
    path: '/AddContactus'
  },
  Cfcoursemanagement: {
    component: Cfcoursemanagement,
    path: '/Cfcoursemanagement'
  },
  Settings2: {
    component: Settings2,
    path: '/Settings2'
  },
  Rolesandpermissions: {
    component: Rolesandpermissions,
    path: '/Rolesandpermissions'
  },
  Timeclock: {
    component: Timeclock,
    path: '/Timeclock'
  },
  Cfadditionalassessmenttest2: {
    component: Cfadditionalassessmenttest2,
    path: '/Cfadditionalassessmenttest2'
  },
  Print: {
    component: Print,
    path: '/Print'
  },
  Comment: {
    component: Comment,
    path: '/Comment'
  },
  CreateComment: {
    component: CreateComment,
    path: '/CreateComment'
  },
  Sorting: {
    component: Sorting,
    path: '/Sorting'
  },
  PostCreation: {
    component: PostCreation,
    path: '/PostCreation'
  },
  Posts: {
    component: Posts,
    path: '/Posts'
  },
  PostDetails: {
    component: PostDetails,
    path: '/PostDetails'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  Search: {
    component: Search,
    path: '/Search'
  },
  Videolibrary2: {
    component: Videolibrary2,
    path: '/Videolibrary2'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },

  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock',
    type: 'Guest'
  },

  Scoring: {
    path: '/EmailAccountLoginBlock'
  },

  CreateNewPassword: {
    component: CreateNewPassword,
    type: 'Guest',
    path: '/CreateNewPassword'
  },

  Scoring: {
    component: Scoring,
    path: '/Scoring'
  },
  Videos: {
    component: Videos,
    path: '/Videos'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  Dashboard: {
    component: Dashboard,
    path: '/Dashboard'
  },
  ImportExportData: {
    component: ImportExportData,
    path: '/ImportExportData'
  },
  Cfgradingcomprules2: {
    component: Cfgradingcomprules2,
    path: '/Cfgradingcomprules2'
  },
  Matchalgorithm4: {
    component: Matchalgorithm4,
    path: '/Matchalgorithm4'
  },
  Profilebio: {
    component: Profilebio,
    path: '/Profilebio'
  },
  TermsConditions: {
    component: TermsConditions,
    path: '/TermsConditions'
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: '/TermsConditionsDetail'
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: '/TermsConditionsUsers'
  },
  Projecttemplates: {
    component: Projecttemplates,
    path: '/Projecttemplates'
  },
  BulkUploading: {
    component: BulkUploading,
    path: '/BulkUploading'
  },
  Uploadmedia3: {
    component: Uploadmedia3,
    path: '/Uploadmedia3'
  },
  LandingPage: {
    component: LandingPage,
    path: '/LandingPage'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  Cfcurriculmschoolyearcreation2: {
    component: Cfcurriculmschoolyearcreation2,
    path: '/Cfcurriculmschoolyearcreation2'
  },
  PerformanceTracker: {
    component: PerformanceTracker,
    path: '/PerformanceTracker'
  },
  PerformanceTrackerCoursesScreen: {
    component: PerformanceTrackerCoursesScreen,
    path: '/PerformanceTrackerCoursesScreen'
  },
  PerformanceTrackerTestsForCourseScreen: {
    component: PerformanceTrackerTestsForCourseScreen,
    path: '/PerformanceTrackerTestsForCourseScreen'
  },
  PerformanceTrackerStudentsOnCourseScreen: {
    component: PerformanceTrackerStudentsOnCourseScreen,
    path: '/PerformanceTrackerStudentsOnCourseScreen'
  },
  PerformanceTrackerTestScoresForStudentScreen: {
    component: PerformanceTrackerTestScoresForStudentScreen,
    path: '/PerformanceTrackerTestScoresForStudentScreen'
  },
  PerformanceTrackerBasicUserCoursesScreen: {
    component: PerformanceTrackerBasicUserCoursesScreen,
    path: '/PerformanceTrackerBasicUserCoursesScreen'
  },
  PerformanceTrackerBasicUserAllCoursesScreen: {
    component: PerformanceTrackerBasicUserAllCoursesScreen,
    path: '/PerformanceTrackerBasicUserAllCoursesScreen'
  },
  PerformanceTrackerStudentRankingScreen: {
    component: PerformanceTrackerStudentRankingScreen,
    path: '/PerformanceTrackerStudentRankingScreen'
  },
  PerformanceTrackerStudentInfoScreen: {
    component: PerformanceTrackerStudentInfoScreen,
    path: '/PerformanceTrackerStudentInfoScreen'
  },
  Analytics: {
    component: Analytics,
    path: '/Analytics'
  },
  PhotoLibrary: {
    component: PhotoLibrary,
    path: '/PhotoLibrary'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  Fileattachment: {
    component: Fileattachment,
    path: '/Fileattachment'
  },
  Cfcurriculmschoolyearcreation22: {
    component: Cfcurriculmschoolyearcreation22,
    path: '/Cfcurriculmschoolyearcreation22'
  },
  ReviewApprovalAdmin: {
    component: ReviewApprovalAdmin,
    path: '/ReviewApprovalAdmin'
  },
  ReviewApprovalBasicUser: {
    component: ReviewApprovalBasicUser,
    path: '/ReviewApprovalBasicUser'
  },
  ParentAssinedAssesment:{
    component:ParentAssinedAssesment,
    path:'/ParentTeacher/Dashboard/Assinedassesment'
  },
  ParentTeacherDashboardViewall: {
    component: ParentTeacherDashboardViewall,
    path: '/ParentTeacher/Dashboard/Viewall'
  }, 
  
  ParentTeacherDashboard: {
    component: ParentTeacherDashboard,
    path: '/ParentTeacher/Dashboard'
  }, 
  
  StudentPage: {
    component: StudentPage,
    path: '/ParentTeacher/Students'
  },
  CourseManagement: {
    component: CourseManagementMain,
    path: '/ParentTeacher/CourseManagement'
  },
  CourseDetailPage: {
    component: CourseDetailPage,
    path: '/ParentTeacher/CourseManagementCourses'
  },
  MyRequestsPage: {
    component: MyRequestsPage,
    path: '/ParentTeacher/CourseManagementRequests'
  },
  CoursesPage: {
    component: CoursesPage,
    path: '/ParentTeacher/CoursesPage'
  },
  JourneyCourseActivityDetail: {
    component: JourneyCourseActivityDetail,
    path: '/ParentTeacher/ActivityDetails'
  },
  ParenTeacherGradebook: {
    component: ParenTeacherGradebook,
    path: '/ParentTeacher/Gradebook'
  },
  GradeUserDetailPage: {
    component: GradeUserDetailPage,
    path: '/ParentTeacher/GradebookUserDetail'
  },
  StudentGradesDetailPage: {
    component: GradebookDetailsPage,
    path: '/Teacher/GradebookGradesDetail'
  },
  ParentTeacherAssessments: {
    component: StudentPerformanceAssessment,
    path: '/ParentTeacher/Assessments'
  },
  ParentTeacherCalendar: {
    component: ParentTeacherCalendar,
    path: '/ParentTeacher/Calendar'
  },
  AssessmentDetailPage: {
    component: AssessmentDetailPage,
    path: '/ParentTeacher/AssessmentDetail'
  },

  Smartcategorisations: {
    component: Smartcategorisations,
    path: '/Smartcategorisations',
    type: 'Guest'
  },
  TaskWeb: {
    component: TaskWeb,
    path: '/TaskWeb',
    type: 'Guest'
  },
  Home: {
    component: AccountLogin,
    path: '/',
    exact: true,
    type: 'Guest'
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '/AlertWeb',
    modal: true
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <>
        <ToastContainer
          autoClose={5000}
          position="bottom-right"
          className="toast-container"
          toastClassName="dark-toast"
          style={{
            zIndex: 2000000
          }}
          newestOnTop
        />
        <View>
          <CustomLoader />
          <AssessmentTestProvider>
          <Router>
            <Switch>
              {WebRoutesGenerator({ routeMap })}
              <Redirect to={'/PageNotFound'} />
            </Switch>
          </Router>
          <ModalContainer />
          </AssessmentTestProvider>
        </View>
      </>
    );
  }
}

export default withRouter(App);
