// Customizable Area Start
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import { runEngine } from "../../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../../framework/src/IBlock";
import {
  ICustomTableProps,  
} from "../../../../components/src/CommonType.web";
import { Message } from "../../../../framework/src/Message";
import {
    displayApiErrorMsg,
  getMessageData,
  hideCustomLoader,
  showCustomLoader,
} from "../../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../../framework/src/Utilities";
import moment from "moment";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./../config.js");

// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  classesList: Array<{
    id: string;
    label: string;
    value: string;
  }>;
  selectedTeacherClassDDValue: string;
  selectedCourseId: any; 
  loading: boolean;
  tableColumns: Array<ICustomTableProps>;
  tableBody: Array<{}>;
  typeOfActivity: string;
  progressStudentsTableBodys: Array<{
    id: number;
    Name: {
      icon: string;
      name: string;
      id: number;
    };
    Progress: {
      value1: number | undefined;
      value2: number | undefined;
      value3: number | undefined;
    };
    TermAvg: string;
    YTD: number;
    LastAccess: string;
  }>;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class ParentTeacherDashboardViewallController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetProgresPerformanceList: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];

    this.state = {
      classesList: [],
      selectedTeacherClassDDValue: "all",
      selectedCourseId: null,  
      progressStudentsTableBodys: [],
      loading: false,
      tableBody: [],
      tableColumns: [],
      typeOfActivity: "",
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start   
    this.handleGetProgressPerformanceStudentsListApis()
    // Customizable Area End
  }

  // Customizable Area Start

  handleGoBack = () => {
    this.props.navigation.goBack();
  };

  handleGetProgressPerformanceStudentsListApis = async () => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = { token };    
    let requestUrl = configJSON.teacherClassCourseStudentsApi;    

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetProgresPerformanceList = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetProgresPerformanceStudentsListRespons = (responseJson: {
    data: Array<{
      id: number;
      attributes: {        
        first_name: string;
        last_name: string;
        profile_url: {
          url: string;
        };
        progress: {          
          not_tackled: number;
          completed: number;
          remaining: number;
          course_wise_progress: Array<{
            id: number,            
            not_tackled_percentage: number,
            completed_percentage: number,
            remaining_percentage: number,
          }>;
        };
        last_access: string;
      };
    }>;
    errors: Array<{}>;
  }) => {
    if (responseJson?.data) {
      const { selectedCourseId } = this.state;
      const studentLists = responseJson.data.map((item) => {
        const studentDetail = item.attributes;
        const progressDetail = studentDetail?.progress?.course_wise_progress?.find((courseItem) => (courseItem.id).toString() === selectedCourseId);
        return {
          id: item.id,
          Name: {
            icon: studentDetail?.profile_url?.url || "",
            name: `${studentDetail.first_name} ${studentDetail.last_name}`,
            id: item.id,
          },
          Progress: {
            value1: !selectedCourseId ? studentDetail?.progress?.completed : progressDetail?.completed_percentage,
            value2: !selectedCourseId ? studentDetail?.progress?.remaining : progressDetail?.remaining_percentage,
            value3: !selectedCourseId ? studentDetail?.progress?.not_tackled : progressDetail?.not_tackled_percentage,
          },
          TermAvg: "0",
          YTD: 0,
          LastAccess: studentDetail?.last_access
            ? moment(studentDetail?.last_access).format("MMMM DD, YYYY")
            : "NA",
        };
      });
      this.setState({ progressStudentsTableBodys: studentLists });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    hideCustomLoader();
  };

  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, responseJson } = getMessageData(message);

      if (apiRequestCallId != null) {
        if (apiRequestCallId === this.apiGetProgresPerformanceList) {
          this.handleGetProgresPerformanceStudentsListRespons(responseJson);
        }        
      }
    }
  }

  // Customizable Area End
}
// Customizable Area End
