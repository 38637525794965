Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.deleteApiMethod = "DELETE";
exports.patchApiMethod = "PATCH";
exports.putApiMethod = "PUT";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "assessmenttest";
exports.labelBodyText = "assessmenttest Body";

exports.btnExampleTitle = "CLICK ME";
exports.numberRegex = /^\d+$/;
exports.updateGradeAndRemarks="bx_block_activity/student_activities/update_total_marks"
exports.teacherAssignmentEndpoint = "bx_block_teacher_assignment/teacher_assignments";
exports.parentTeacherAssignmentEndpoint ="bx_block_activity/activities/get_parent_teacher_assessments"
exports.teacherAssignmentFiltersEndpoint = "bx_block_teacher_assignment/teacher_assignments/fetch_filters";
exports.teacherBasedOnGradeSubjectCurriculum = "account_block/users/get_teacher_basedon_grade_curriculum_subjects";
exports.subjectsEndpoint = "bx_block_subject/subjects";
exports.classesEndpoint = "bx_block_class_management/class_managements";
exports.curriculumEndpoint = "bx_block_curriculum/curriculums";
exports.teacherAssessmentEndpoint = "bx_block_activity/activities/assessments";
exports.teacherClassesEndpoint = "account_block/users/find_teacher_assign_classes";
exports.userDetailsEndpoint = "account_block/users";
exports.studentAssessmentEndpoint = "bx_block_questionbank/student_answers";
exports.getSubjectsDropdwonData = "bx_block_subject/subjects";
exports.postAddNewActivity = "bx_block_activity/activities";
exports.getCourseListData = "bx_block_profile/courses";
exports.postAddNewTopic = "bx_block_profile/topics";
exports.postAddNewLesson = "bx_block_profile/lessons";
exports.getTeacherCoursesEndpoint = "bx_block_profile/courses/get_teacher_courses_tab";
exports.getParentTeacherCourseEndpoint="bx_block_dashboard/parent_teacher_dashboard/get_parent_teacher_courses"
exports.updateStudentTotalMark = "bx_block_activity/student_activities/update_total_marks";
exports.searchStudent ='account_block/users/get_class_based_students'
exports.getAllSearchStudents ='bx_block_activity/activities/student_wise_assessments'
// Customizable Area End