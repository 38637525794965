// Customizable Area Start
import { BlockComponent } from "../../framework/src/BlockComponent";
import { Message } from "../../framework/src/Message";
import { runEngine } from "../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../framework/src/IBlock";
import { getStorageData, removeStorageData } from "../../framework/src/Utilities";
import { handleLogoutUser } from "./CommonHelper.web";
import { USER_ROLES } from "./CommonType.web";
// Customizable Area End

// Customizable Area Start
// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation: any;
  id?: string;
  classes: any;
  handleToggleSidebar: any;
  showHamburgerIcon: boolean;
  handleToggleThemeSettings: any;
  isDarkMode: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  fullName: any;
  menuAnchorEl: any;
  profileRole: any;
  logoUrl:string;
  customizedData:boolean
  // Customizable Area End
}


interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class WebHeaderController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetUserData: string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ]

    this.state = {
      loading: false,
      fullName: "",
      menuAnchorEl: null,
      profileRole: '',
      logoUrl:'',
      customizedData:false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    await super.componentDidMount();
    // Customizable Area Start
    this.setState({
      profileRole: localStorage.getItem('role'),
      fullName: localStorage.getItem('user_name'),
    })
    window.addEventListener('appearanceTheme', this.getCompanyLogo);
    this.getCompanyLogo()
    // Customizable Area End
  }

  // Customizable Area Start
  componentWillUnmount(): any {
    window.removeEventListener('appearanceTheme', this.getCompanyLogo);
  }
  getCompanyLogo =async () => {
    const themeData = await getStorageData("appearanceTheme");
    if (themeData) {
      try {
          const appearanceThemes = JSON.parse(themeData);
          if(appearanceThemes.customized_branding){
            this.setState({logoUrl:appearanceThemes?.logo_url?.url})
          }else{
            this.setState({logoUrl:""})
          }
      } catch (error) {
        console.error("Failed to parse themeData. It might not be valid JSON.", error);
      }
    } else {
      console.warn("No themeData found in local storage.");
    }
    // this.setState({companyName:"Thynker Tech"})
  };
  handleActionClick = (e: any) => {
    this.setState({ menuAnchorEl: e?.currentTarget }) 
  }

  handleMenuClose = () => {
    this.setState({ menuAnchorEl: null });
  }

  handleGotoProfile = async() => {
    const roleFromStorage = await getStorageData("role");
    if (roleFromStorage.toLowerCase() === USER_ROLES.STUDENT) return this.props.navigation.navigate("StudentProfile");
    if (roleFromStorage.toLowerCase() === USER_ROLES.TEACHER) return this.props.navigation.navigate("TeacherProfile");
    if (roleFromStorage.toLowerCase() === USER_ROLES.PARENT_TEACHER) return this.props.navigation.navigate("ParentTeacherProfile");
    else if (roleFromStorage.toLowerCase() === USER_ROLES.REGISTRAR) return this.props.navigation.navigate("RegistrarProfile");
    return this.props.navigation.navigate("StudentProfile");
  }

  handleLogout = async () => {
    const { navigation } = this.props;
    await handleLogoutUser(navigation);
  }

  async receive(from: string, message: Message) {

    console.log("rece-self");
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      console.log("apiRequestCallId", apiRequestCallId)


      // Customizable Area Start

      // Customizable Area End
    }
  }

  // Customizable Area End
}