// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { Message } from "../../../framework/src/Message";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { IBlock } from "../../../framework/src/IBlock";
import { tableColumnTypes } from "../../../components/src/CustomTable.web";
import { Alert } from "./assets";
import { displayApiErrorMsg, getMessageData, hideCustomLoader, showCustomLoader } from "../../../components/src/CommonHelper.web";
import { getStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import React from "react";
import { BoxProps } from "@material-ui/core";
import { toast } from "react-toastify";
import { TOASTER_NOTIFICATION_MSG } from "../../../components/src/CommonType.web";
// Customizable Area End

// Customizable Area Start
const configJSON = require("./config.js");

export interface ITableProps {
  id: string;
  label: string;
  type: string;
  columnId: number;
  width?: string;
  bgColor?: string;
}
interface Student {
  id: number;
  first_name: string;
  last_name: string;
}

interface Subject {
  name: string;
  icon: string;
  bgColor: string;
}
interface Activity {
  id: string;
  assignedon: string; 
  daysDue: number;
  dueDate: string; 
  gradingComponent: string;
  students: Student[];
  subject: Subject;
}
interface Student {
  id: number;
  first_name: string;
  last_name: string;
  profile_pic: string;
  date_of_submission: string | null;
  time_spent: number;
  average: number;
  status: string;
}

interface Students {
  data: Student[];
}

interface SelectedSubject {
  id: number;
  name: string;
  students: Students;
}


// Customizable Area End

export interface Props {
  // Customizable Area Start
  navigation?: any;
  id?: string;
  classes?: any;
  classId: string[]|string;
  searchByStudent: string;
  studentIds:string| number;
  getStudentData:any,
  searchstu:boolean,
  currentRole:string
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  selectedStudentName: string;
  courseName: string;
  selectedClassComponentDDValue: any;
  selectedStatusComponentDDValue: any;
  selectedSubjectComponentDDValue: any;
  gradingAssignedComponent: Array<any>;
  subjectComponent: Array<any>;
  searchStudent: string;
  schoolSubjectsList: any;
  selectedSubjectId: any;
  selectDetailsId:any;
  assessmentStudents: any;
  subjectTableColumns: Array<ITableProps>;
  assessmentStudentsColumns: Array<ITableProps>;
  selectedAssessmentName: string;
  selectedStudentId: string;
  paginationData: any;
  timer: any;
  tooltipMessage:string;
  editGradeStudentId: number;
  editGradeStudentName: string;
  editGradeTotalMarks: number;
  editGradeMaxScore: number;
  openEditGradeAndRemarkModal: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
export default class AssessmentAssignedController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetProfileData: string = "";
  apiGetAssignedAssessments: string = "";
  apiGetSubjectsDropdownData: string = "";
  apigetAllSearchStudents: string = "";
  getAssignSearchStudentsId:string="";
  apiUpdateTotalMarks:string="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.AccoutLoginSuccess),
    ];

    this.state = {
      tooltipMessage:"",
      selectedStudentName: "",
      courseName: "",
      searchStudent: "",
      gradingAssignedComponent: [
        {
          id: 1,
          label: "Quiz",
          value: "quiz",
        },
        {
          id: 2,
          label: "Exam",
          value: "exam",
        },
        {
          id: 3,
          label: "Assignment",
          value: "assignment",
        },
        {
          id: 4,
          label: "Project",
          value: "project",
        }
      ],
      editGradeStudentId: 0,
      editGradeStudentName: '',
      editGradeTotalMarks: 0,
      openEditGradeAndRemarkModal:false,
      editGradeMaxScore: 0,
      subjectComponent: [],
      selectedClassComponentDDValue: "",
      selectedStatusComponentDDValue: "",
      selectedSubjectComponentDDValue: "",
      selectedSubjectId: 1,
      selectDetailsId:1,
      assessmentStudents: [],
      schoolSubjectsList: [],
      subjectTableColumns: [
        {
          id: "subject",
          columnId: 1,
          label: "Assessment Title",
          type: tableColumnTypes.SUBJECT_ICON_NAME,
          width: "14%",
        },
        {
          id: "gradingComponent",
          columnId: 2,
          label: "Grading Component",
          type: tableColumnTypes.TEXT,
          width: "14%",
        },
        {
          id: "submission",
          columnId: 3,
          label: "Submission %",
          type: tableColumnTypes.STUDENT_SUBMISSION_PROGRESS,
          width: "14%",
        },
        {
          id: "classAvg",
          columnId: 4,
          label: "Class Avg",
          type: tableColumnTypes.ASSIGNED_ICON,
          width: "14%",
        },
        {
          id: "assignedon",
          columnId: 4,
          label: "Assigned On",
          type: tableColumnTypes.ASSIGNED_ICON,
          width: "30%",
        },
        {
          id: "dueDate",
          columnId: 5,
          label: "Due Date",
          type: tableColumnTypes.TEXT,
          width: "30%",
        },
        {
          id: "daysDue",
          columnId: 6,
          label: "Days Due",
          type: tableColumnTypes.TEXT,
          width: "14%",
        },
        {
          id: "moreMenu",
          columnId: 7,
          label: "",
          type: tableColumnTypes.TEXT,
          width: "5%",
        },
      ],
      assessmentStudentsColumns: [
        {
          id: "title",
          columnId: 1,
          label: "Name",
          type: tableColumnTypes.STUDENT_ICON,
        },
        {
          id: "DateSubmitted",
          columnId: 2,
          label: "Date Submitted",
          type: tableColumnTypes.NUMBER,
        },
        {
          id: "timeEllapsed",
          columnId: 3,
          label: "Time Ellapsed",
          type: tableColumnTypes.NUMBER_LESS_WIDTH,
        },
        {
          id: "average",
          columnId: 4,
          label: "Average",
          type: tableColumnTypes.ASSIGNED_ICON,
        },
        {
          id: "status",
          columnId: 5,
          label: "Status",
          type: tableColumnTypes.CHIP,
          width: "250px",
        },
      ],
      selectedAssessmentName: "",
      selectedStudentId: "",
      paginationData: {},
      timer: null,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.handleGetSubjectsDropdownListApi();
    this.handleAPICall()
    // Customizable Area End
  }
  // Customizable Area Start

  componentDidUpdate(prevProps: Readonly<Props>): void {
    if (this.props.classId && prevProps.classId !== this.props.classId) {
      this.handleGetAssignedAssessmentsApi({}, {});
    }
    if ((prevProps.searchByStudent !== this.props.searchByStudent)) {
      this.handleAPICall()
    }
  }
  handleAPICall=()=>{
    if(this.props.searchByStudent.length > 0 && this.props.searchByStudent !== "All"){
      this.handleGetSearchStudentApi(this.props.studentIds,{})
    }else{
      this.handleGetAssignedAssessmentsApi({}, {})
    }
  }
  async receive(from: string, message: Message) {
    runEngine.debugLog("on recieive==>" + JSON.stringify(message));
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, errorResponse, responseJson } = getMessageData(message);
      if (apiRequestCallId != null) {

        if (apiRequestCallId === this.apiGetAssignedAssessments) {
          this.handleGetAssignedAssessmentsResponse(
            responseJson,
            errorResponse
          );
        }

        if (apiRequestCallId === this.apiGetSubjectsDropdownData) {
          this.handleGetSubjectsDropdownResponse(
            responseJson,
            errorResponse
          );
        }
        if(apiRequestCallId === this.getAssignSearchStudentsId){
          this.handleGetAssignSearchSearch(
            responseJson,
            errorResponse
          );          
        }
        if(apiRequestCallId === this.apiUpdateTotalMarks){
          this.handleUpdateGradeResponse(
            responseJson,
            errorResponse
          )
        }

      }
    }
  }

  handleSubjectAssignedTable = (subjectId: any) => {
    const { schoolSubjectsList, selectedSubjectId } = this.state;
    if (subjectId === selectedSubjectId) {
      this.setState({ selectedSubjectId: "" });
    } else {
      let studentsList: Array<any> = []
      const selectedSubjectObj: any = schoolSubjectsList.find(
        (subject: any) => subject?.id === subjectId
      );

      if (selectedSubjectObj?.students) {
        const studentsData: Array<any> = selectedSubjectObj.students.data;

        studentsList = studentsData.map((item: any) => {
          return {
            id: item?.id,
            title: {
              id: item?.id,
              name: `${item?.first_name} ${item?.last_name}`,
              icon: item?.profile_pic,
            },
            DateSubmitted: item.date_of_submission ? moment(item.date_of_submission).format('MMMM DD, YYYY') : 'NA',
            timeEllapsed: this.formatTimeSpent(item?.time_spent || 0),
            average: {
              icon: "",
              name: (item?.average || 0) + '%',
            },
            status: item?.status || '',
            totalMarks: item?.total_marks,
            maxScore: selectedSubjectObj?.maxScore,
          }
        });
      }
      this.setState({
        assessmentStudents: studentsList,
        selectedSubjectId: subjectId,
        selectDetailsId:subjectId,
        selectedAssessmentName: selectedSubjectObj?.subject?.name
      });
    }
  };
  handleSearchStudent = (event: any) => {
    this.setState({
      searchStudent: event.target?.value,
    });
  };
  handleGradingDropDownValues = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let fieldValues: any = value;
    if (fieldValues) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValues,
        };
      });
    }
  };
  renderGradingDDValues = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Option";
    }
    const { gradingAssignedComponent } = this.state;
    const selectedGradingComponents = gradingAssignedComponent.find(
      (grade: any) => grade?.value === selected
    );
    return selectedGradingComponents?.label;
  };
  handleSelectSubjectDDValue = (
    event: React.ChangeEvent<{
      name?: any;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => {
    const { name, value } = event.target;
    let fieldValues: any = value;
    if (fieldValues) {
      this.setState((prev) => {
        return {
          ...prev,
          [name]: fieldValues,
        };
      });
    }
  };
  handleSelectSubjectComponenttDDValues = (selected: any) => {
    if (!selected || selected.length === 0) {
      return "Select Subject";
    }
    const { subjectComponent } = this.state;
    const selectedDueDateComponents = subjectComponent.find(
      (grade: any) => grade?.value === selected
    );
    return selectedDueDateComponents?.label;
  };

  openAssessmentDetailPage = (data: any) => {
    const { selectedAssessmentName, selectedSubjectId } = this.state;
    this.props.navigation.navigate("AssessmentStudentView", {}, {
      assessmentName: selectedAssessmentName,
      assessmentId: selectedSubjectId,
      studentName: data.name,
      studentId: data.id,
    });
  }

  handleGetAssignedAssessmentsApi = async (searchQuery: any, pagination: any) => {
    showCustomLoader();
    const {currentRole} = this.props
    let apiConfig = currentRole==="parent teacher"? configJSON.parentTeacherAssignmentEndpoint:configJSON.teacherAssessmentEndpoint;
    let pageLimit = 10;
    if (searchQuery.studentSearch) {
      pageLimit = 100;
    }
    const baseUrl = apiConfig + `?type=assigned&per_page=${pageLimit}`;
    let requestUrl = baseUrl;

    if (this.props.classId && currentRole!=="parent teacher") {
      requestUrl = requestUrl + `&class_id=[${this.props.classId}]`;
    }

    if (searchQuery.grading_component) {
      requestUrl = requestUrl + `&grading_component=${searchQuery.grading_component}`;
    }

    if (searchQuery.subject_id) {
      requestUrl = requestUrl + `&subject_id=${searchQuery.subject_id}`;
    }

    if (searchQuery.title) {
      requestUrl = requestUrl + `&title=${searchQuery.title}`;
    }

    if (pagination.page) {
      requestUrl = requestUrl + `&page=${pagination.page}`;
    }

    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetAssignedAssessments = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  getWidth=(width:string)=>{
    return this.props.searchstu ?width:""
  }
  getClassName = (subjectItem: number | string
  ) => {
    const classes = this.props.classes
    if (subjectItem === this.state.selectedSubjectId) {
      return `${classes.show} ${classes.subjectCoursesDetailsTable} ${classes.CoursesTableBox} ${classes.secondaryBgColor}`
    }
    else {
      return `${classes.hide} ${classes.subjectCoursesDetailsTable} ${classes.CoursesTableBox} ${classes.secondaryBgColor}`
    }
  }
  getclassNames = (subjectItem: number | string) => {
    const classes = this.props.classes
    if (subjectItem === this.state.selectedSubjectId) {
      return `${classes.show}`
    }
    else {
      return `${classes.hide}`
    }
  }
  getTopRightRed = (subjectItem: number) => {
    if (subjectItem === 100) {
      return "7px"
    }
    else {
      return "0px"
    }
  }
  getIcon = (subjectItem: number | string) => {
    const classes = this.props.classes
    if (subjectItem === this.state.selectedSubjectId) {
      return <RemoveIcon
        className={`${classes.textCommonColor} ${classes.cursorPointer}`}
      />
    }
    else {
      return <AddIcon
        className={`${classes.textCommonColor} ${classes.cursorPointer}`}
      />
    }

  }
    
  handleGetAssignedAssessmentsResponse = (responseJson: any, errorResponse: any) => {
    
    if (responseJson.data) {
      let assessmentList = responseJson.data.map(
        (item: any, index: number) => {
          const activity = item.attributes;
          return {
            id: item.id,
            subject: {
              name: activity.activities_title,
              icon: activity.subject.icon,
              bgColor: activity.subject.color,
            },
            subjectId: activity.subject.id,
            objectiveType:activity.objective_type,
            evolutionType:activity.evolution_type,
            gradingComponent: activity.grading_component,
            assignedon:activity.assigned_on,
            submission: {
              value: activity.submission_percenttage,
            },
            classAvg: {
              icon: Alert,
              name: activity?.students?.class_avg,
            },
            dueDate: activity?.due_date ? moment(activity?.due_date).format('MMMM DD, YYYY') : 'NA',
            daysDue: activity.due_days,
            students: activity.students,
            maxScore: activity?.max_score,
          };
        }
      );
      let pagination = responseJson?.meta?.pagination;
      let updatedAssessmentList = assessmentList;
      this.setState({ schoolSubjectsList: updatedAssessmentList, paginationData: pagination },()=>{
        if (this.state.selectDetailsId) {
          const studentId = this.state.selectDetailsId;
          this.handleSubjectAssignedTable(studentId);
          setTimeout(() => {
            this.handleSubjectAssignedTable(studentId);
          }, 800);
        }
      });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };
  handleGetAssignSearchSearch = async (responseJson: any, errorResponse: []) => {
    if (responseJson &&  responseJson.data) {
      let assessmentList:any[] = responseJson.data.map(
        (item:any) => {
          const activityAssign = item.attributes;
   
          return {
            id: item.id,
            subject: {
              name: activityAssign.activities_title,
              icon: activityAssign.subject.icon,
              bgColor: activityAssign.subject.color,
            },
            subjectId: activityAssign.subject.id,
            gradingComponent: activityAssign.grading_component,
            assignedon: activityAssign.assigned_on
              ? moment(activityAssign.assigned_on).format('MMMM DD, YYYY')
              : 'NA',
            dueDate: activityAssign.due_date
              ? moment(activityAssign.due_date).format('MMMM DD, YYYY')
              : 'NA',
            daysDue: activityAssign.due_days,
            students: responseJson.student,
          } ;
        }
      );
      let pagination = responseJson?.meta?.pagination;
      this.setState({
        schoolSubjectsList: assessmentList,
        paginationData: pagination
      });
      
    }
  
    hideCustomLoader();
  };

  handleGetSearchStudentApi = async (studentId:string | number,pagination:any) => {      
    
    let pageLimit = 10;
    let requestUrl =configJSON.getAllSearchStudents + `?student_activity=assigned_activity&per_page=${pageLimit}&student_id=${studentId}`
    if (pagination.page) {
      requestUrl = requestUrl + `&page=${pagination.page}`;
    }
    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.getAssignSearchStudentsId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetSubjectsDropdownListApi = async () => {
    showCustomLoader();
    const apiConfig = configJSON.getTeacherCoursesEndpoint;
    const requestUrl = apiConfig + `?per_page=500&page=1`;

    const token = await getStorageData("token");
    const header = {
      token,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    this.apiGetSubjectsDropdownData = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleGetSubjectsDropdownResponse = (responseJson: any, errorResponse: any) => {
    if (responseJson.data) {
      const subjectsDDList = responseJson?.data?.map(
        (item: any) => {
          return {
            id: item.attributes.subject.id,
            label: item.attributes.subject.name,
            value: item.attributes.subject.id,
          };
        }
      );
      let removedDuplicates = this.removeDuplicates(subjectsDDList);
      this.setState({
        subjectComponent: removedDuplicates,
      });
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
  };
  
  getSearchFiltersObject = () => {
    const {
      searchStudent,
      selectedSubjectComponentDDValue,
      selectedClassComponentDDValue,
    } = this.state;

    const searchFilters = {
      title: searchStudent,
      subject_id: selectedSubjectComponentDDValue,
      grading_component: selectedClassComponentDDValue,
    }
    return searchFilters;
  }

  handleSearchAssignedAssessments = () => {
    const filters = this.getSearchFiltersObject();
    this.handleGetAssignedAssessmentsApi(filters, {});
  }

  handlePagination = (event: any, page: number) => {
    const filters = this.getSearchFiltersObject();
    if(this.props.searchByStudent.length > 0 && this.props.searchByStudent !== "All"){
      this.handleGetSearchStudentApi(this.props.studentIds,{ page: page })
    }else{
      this.handleGetAssignedAssessmentsApi(filters, { page: page });this.handleGetAssignedAssessmentsApi(filters, { page: page });
    }
    
  }

  handleSearchAssessmentByStudent = (student: string) => {
    const { timer } = this.state;
    if (student.length >= 1) {
      clearTimeout(timer)
      const newTimer = setTimeout(() => {
        this.handleGetAssignedAssessmentsApi({ studentSearch: student }, {});
      }, 500)
      this.setState({ timer: newTimer });
    } else {
      this.handleGetAssignedAssessmentsApi({}, {});
    }
  }

  removeDuplicates = (arr: any) => {
    const seen = new Set<number>();
    return arr.filter((item: any) => {
      if (seen.has(item.id)) {
        return false;
      } else {
        seen.add(item.id);
        return true;
      }
    });
  }

  formatTimeSpent = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    const pad = (num: any) => String(num).padStart(2, '0');
    return `${pad(hours)}:${pad(minutes)}:${pad(remainingSeconds)}`;
  }

  resetFilterDrodownData = () => {
    this.setState({
      selectedClassComponentDDValue: "",
      selectedStatusComponentDDValue: "",
      selectedSubjectComponentDDValue: "",
    }, () => {
      this.handleGetAssignedAssessmentsApi({}, {});
    })
  }

  resetCurrentSelectionData = () => {
    this.setState({
      selectedSubjectId: 1,
      selectedAssessmentName: "",
      selectedStudentId: "",
      paginationData: {},
    })
  }
  getClassNameHeadRow = (
    GradeTableHeadingRow: React.ComponentType<BoxProps>,
    wideFirstChild: React.ComponentType<BoxProps>
  ): React.ComponentType<BoxProps> => {
    return this.props.searchstu ? wideFirstChild : GradeTableHeadingRow;
  };
  getClassNameRow = (secondRow:React.ComponentType<BoxProps>, wideChildRow:React.ComponentType<BoxProps>) => {
    return this.props.searchstu ? wideChildRow : secondRow;
  };
  handleShow = (selectedSubjectId: number) => {
    const selectedSubjectObj:SelectedSubject = this.state.schoolSubjectsList.find(
      (subject: SelectedSubject) => subject?.id === selectedSubjectId
    );   
   
    if ( selectedSubjectObj && selectedSubjectObj.students) {   
      const studentsData = selectedSubjectObj.students.data ;

        const studentsList = studentsData && studentsData.map((item: any) => {
        return  item.average | 0 });   
        if (studentsList) {     
        const totalStudentsCount = studentsList.length;
        const zeroCount = studentsList.filter((average: number) => average === 0);
       const zeroAverageCount = zeroCount.length      
        const tooltipMessage = `${zeroAverageCount} out of ${totalStudentsCount} have not been graded`;
      return tooltipMessage
        }
    }
  } 
  showError=(subjectItemId:number) => {
    return !this.props.searchstu ? this.handleShow(subjectItemId) :''
  } 

  handleEditGradeDialog = (studentId: number) => {
    const { assessmentStudents } = this.state;
    const student = assessmentStudents.find((item: any) => item.id === studentId);
    const studentFullName = student?.title?.name;
    this.setState({
      editGradeStudentId: studentId,
      editGradeStudentName: studentFullName,
      editGradeTotalMarks: student?.totalMarks || 0,
      editGradeMaxScore: student?.maxScore || 0,
    },()=>{
      this.handleOpenEditGradeRemarkModal();
    });
  };

  handleOpenEditGradeRemarkModal = () => {
    this.setState({ openEditGradeAndRemarkModal: true });
  }
  handleCloseEditGradeAndRemarkModal = () => {
    this.setState({ openEditGradeAndRemarkModal: false });
  }

  handleSaveEditGrade = (payload: any) => {
    this.handleSaveEditGradeApi(payload);
  }

  handleSaveEditGradeApi = async (requestBody: any) => {
    showCustomLoader();
    const token = await getStorageData("token");
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token,
    };

    const requestUrl = configJSON.updateGradeAndRemarks;
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiUpdateTotalMarks = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      requestUrl
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(requestBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.putApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleUpdateGradeResponse = (
    responseJson: any,
    errorResponse: any
  ) => {
    if (responseJson?.data) {
      toast.success(TOASTER_NOTIFICATION_MSG.GRADE_UPDATE_SUCCESS);
      const filters = this.getSearchFiltersObject();
      this.handleGetAssignedAssessmentsApi(filters, this.state.paginationData);
    this.handleCloseEditGradeAndRemarkModal();      
    } else {
      displayApiErrorMsg(responseJson?.errors, this.props.navigation);
    }
    this.parseApiCatchErrorResponse(errorResponse);
    hideCustomLoader();
  };

  resetEditGradeData = () => {
    this.setState({
      editGradeStudentId: 0,
      editGradeStudentName: '',
      editGradeTotalMarks: 0,
      editGradeMaxScore: 0,
    });
  }
  // Customizable Area End
}
// Customizable Area End
