// Customizable Area Start
import React from 'react';
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import {  Box, Grid, Typography } from '@material-ui/core';
import { customThemeStyles } from '../../CustomThemeStyles.web';
import { combineStyles } from '../../CombineStyles.web';


import { profilePic1 } from '../../gradebookDetails/assets';
import StudentDetailsController,{Props} from './StudentDetailsController';
import CustomImageComponentWeb from '../../CustomImageComponent.web';
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

// Customizable Area End
// Customizable Area Start
const styles = (theme: Theme) => 
    createStyles({
        fieldWrapper:{
            display: "flex",
            gap: "15px",
            alignItems: "center",
            // padding: "25px"
        },
        inputLabel: {
            margin: theme.spacing(2.5, 0),
            color: "#888888",
            fontSize: "16px",
            fontWeight: 400
          },
          inputLabel2:{
            margin: 0,
            color: "#888888",
            fontSize: "16px",
            fontWeight: 400
          },
        boxWrapper: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            // padding: "0 25px"
          },
        fieldWrapper2:{
            display: "flex",
            gap: "15px",
            alignItems: "center",
            marginTop:"10px"
            // paddingLeft: "25px"
        },
        // subjectLogoBg: {
        //     borderRadius: "50%",
        //     display: "flex",
        //     alignItems: "center",
        //     justifyContent: "center",
        //     minWidth: "45px",
        //     minHeight: "45px",
        //     height: "45px",
        //     width: "45px",
        // },



        studentInfoBox: {
          borderRadius: "10px",
          padding: "0px 0px 8px 0px",
        },
        studentPageName: {
          marginTop: "16px",
          marginBottom: "28px",
        },
        studentProfileInfoGrid: {
          padding: "15px 18px 15px 18px",
          borderBottom: "4px solid #f0f0f0",
        },
        studentProfileInfoGridLast: {
          padding: "15px 18px 15px 18px",
        },
        mainPagePadding: {
          paddingRight: "20px",
        },
        textCapital: {
          textTransform: "capitalize",
        },
        userName: {
          padding: "15px 18px 5px 18px",
        },
        leftMargin: {
          marginLeft: "8px",
        },
        detailGridItem: {
          padding: "8px 0px 8px 0px",
        },
        subjectLogoBg: {
          width: "55px",
          borderRadius: "50%",
          display: "flex",
          height: "55px",
          justifyContent: "center",
          alignItems: "center",
          padding: "2px",
        },
        subjectImg: {
          objectFit: "contain",
        },
        subjectCard: {
          padding: "15px 22px",
          borderRadius: "10px",
          width: "84%",
          marginBottom: "15px",
          [theme.breakpoints.down("xs")]: {
            width: "85% !important",
          },
          [theme.breakpoints.down("sm")]: {
            width: "75%",
          },
        },
        overflowText: {
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        },
        chartWrapper: {
          position: "relative",
          "& canvas": {
            position: "absolute",
            left: 0,
            pointerEvents: "none",
            top: 0,
            minWidth: "auto",
            height: "auto !important",
          },
        },
        chartAreaWrapper: {
          width: "100%",
          overflowX: "auto",
          position: "relative",
        },
        chartAreaSecondWrapper: {
          position: "relative",
          height: "268px",
        },
        progressPercentageMainBox: {
          display: "flex",
          padding: "0% 5%",
          justifyContent: "space-between",
          flexWrap: "wrap",
        },
        progressPerformanceContainer: {
          padding: "15px 22px",
          borderRadius: "10px",
        },
        emptyProfileImg: {
          width: "inherit",
          height: "inherit",
          color: "#ccc",
        },
        profileImgBox: {
          width: "55px",
          height: "55px",
          borderRadius: "50%",
        },
        profileImg: {
          width: "inherit",
          height: "inherit",
          objectFit: "cover",
          borderRadius: "50%",
        },
    })

// Customizable Area End

// Customizable Area Start

export class StudentDetails extends StudentDetailsController  {
    // Customizable Area Start
    constructor(props: Props) {
        super(props);
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes,studentData,schoolYear} = this.props;
        const {
          
        } = this.state;
        
        return (
          <Box style={{ width: "100%" }}>
            <Box style={{ padding: "20px" }}>
              <Box className={classes.fieldWrapper}>
                <img
                  alt="student-profile"
                  className={classes.subjectLogoBg}
                  src={this.props.studentData?.profile_pic || require("../../empty_user_profile.png")}
                  onError={(e) => { e.currentTarget.src = profilePic1 }} // Fallback to default if the image fails to load
                />
                <Typography
                  className={`${classes.studentHeader} ${classes.textPrimaryColor}`}
                >
                  {this.props.studentData ? this.props.studentData.full_name : " Antonin Hafer"}{" "}
                </Typography>
              </Box>
              <Box style={{overflow:"scroll",gap:"25px"}} className={classes.boxWrapper}>
                <Box>
                  <Typography className={classes.inputLabel}>
                    Email ID:{" "}
                    <span
                      className={`${classes.fieldValue} ${classes.textPrimaryColor}`}
                    >
                      {this.props?.studentData ? this.props.studentData?.email : "antoninhafer521@gmail.com"}{" "}
                    </span>
                  </Typography>
                  <Typography className={classes.inputLabel}>
                    Date of Birth:{" "}
                    <span
                      className={`${classes.fieldValue} ${classes.textPrimaryColor}`}
                    >
                      {this.props?.studentData?.dob || "Jan 06,2008"}{" "}
                    </span>
                  </Typography>
                  <Typography className={classes.inputLabel}>
                    Role:{" "}
                    <span
                      className={`${classes.fieldValue} ${classes.textPrimaryColor}`}
                    >
                      {this.props?.studentData ? this.props.studentData?.role : "Student"}{" "}

                    </span>
                  </Typography>
                  {studentData.customFields[0] && (
                    <Typography className={classes.inputLabel}>
                      {studentData.customFields[0].label}:{" "}
                      <span
                        className={`${classes.fieldValue} ${classes.textPrimaryColor}`}
                      >
                        {studentData.customFields[0].value || "N/A"}
                      </span>
                    </Typography>
                  )}
                </Box>
                <Box>
                  <Typography className={classes.inputLabel}>
                    ID Number:{" "}
                    <span
                      className={`${classes.fieldValue} ${classes.textPrimaryColor}`}
                    >
                      {this.props.studentData?.id_number || "5201"}
                    </span>
                  </Typography>
                  <Typography className={classes.inputLabel}>
                    Grade Level:{" "}
                    {this.props.studentData?.grade.length> 0  ? this.props.studentData?.grade?.map((gradeItem: any) => (
                    <span
                      className={`${classes.fieldValue} ${classes.textPrimaryColor}`}
                    >
                      {gradeItem.name}
                    </span>
                    )) :
                    <span
                    className={`${classes.fieldValue} ${classes.textPrimaryColor}`}
                  >
                    Grade 1
                  </span>
                     } 
                  </Typography>
                  <Typography className={classes.inputLabel}>
                    Class:{" "}
                    <span
                      className={`${classes.fieldValue} ${classes.textPrimaryColor}`}
                    >
                      {this.props.className && this.props.className.trim().length > 0 ? this.props.className : "Class 9A"}
                    </span>
                  </Typography>
                  {studentData.customFields[1] && (
                    <Typography className={classes.inputLabel}>
                      {studentData.customFields[1].label}:{" "}
                      <span
                        className={`${classes.fieldValue} ${classes.textPrimaryColor}`}
                      >
                        {studentData.customFields[1].value || "N/A"}
                      </span>
                    </Typography>
                  )}
                </Box>
                <Box>
                  <Typography className={classes.inputLabel}>
                    Curriculam:{" "}
                    <span
                      className={`${classes.fieldValue} ${classes.textPrimaryColor}`}
                    >
                      {this.props.studentData?.curriculum || "Curriculum A"}
                    </span>
                  </Typography>
                  <Typography className={classes.inputLabel}>
                    School Year Title:{" "}
                    <span
                      className={`${classes.fieldValue} ${classes.textPrimaryColor}`}
                    >
                      {this.props.schoolYear || "2024"}
                    </span>
                  </Typography>
                  <Typography className={classes.inputLabel}>
                    Expiring On:{" "}
                    <span
                      className={`${classes.fieldValue} ${classes.textPrimaryColor}`}
                    >
                      {this.props.studentData?.expiringOn || "31/9/2025"}
                    </span>
                  </Typography>
                  {studentData.customFields[2] && (
                    <Typography className={classes.inputLabel}>
                      {studentData.customFields[2].label}:{" "}
                      <span
                        className={`${classes.fieldValue} ${classes.textPrimaryColor}`}
                      >
                        {studentData.customFields[2].value || "N/A"}
                      </span>
                    </Typography>
                  )}
                </Box>

              </Box>
            </Box>
          </Box>

        
        );

        // Customizable Area End
    }
}
// Customizable Area End

// Customizable Area Start
const saveButtonStyle = {
    height: "50px",
    padding: "10px 10px",
    fontSize: "18px",
    width: "120px",
    borderRadius: "12px",
    textAlign: "center",
    marginTop: "10px",
    float: "right",
    fontWeight: 500,
    marginLeft: "15px",
} as const;

const cancelButtonStyle = {
    padding: "10px 10px",
    width: "120px",
    height: "50px",
    borderRadius: "12px",
    border: "1px solid",
    fontSize: "18px",
    fontWeight: 500,
    textAlign: "center",
    marginTop: "10px",
    float: "right",
    marginLeft: "15px",
} as const;

const customInputStyle = {
    width: "99%",
    height: "36px",
    marginTop: "10px",
} as const;

const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(StudentDetails);
// Customizable Area End
