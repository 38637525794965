// Customizable Area Start
import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { createStyles, withStyles, Theme } from "@material-ui/core/styles";
import StudentProfileController, {
  Props
} from "./StudentProfileController.web";
import { customThemeStyles } from "../../../components/src/CustomThemeStyles.web";
import { combineStyles } from "../../../components/src/CombineStyles.web";
import { studentProfile, parent1, parent2 } from "./assets";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import GearIconDropDown from "../../../components/src/GearIconDropDown.web";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CustomImageComponentWeb from "../../../components/src/CustomImageComponent.web";
// Customizable Area End

// Customizable Area Start
const styles = (theme: Theme) =>
  createStyles({
    fieldValue: {
      color: "#090909",
      fontSize: "16px",
      fontWeight: 600,
    },
    inputLabel: {
      color: "#888888",
      fontSize: "16px",
      fontWeight: 400,
    }, 
    parentHeader: {
      color: "#090909",
      fontSize: "20px",
      fontWeight: 600,
      padding: "25px",
      borderBottomLeftRadius: "12px",
      borderBottomRightRadius: "12px",
    },
    fieldBox: {
      margin: theme.spacing(2.5, 0),
      backgroundColor: "#FFFFFF",
      borderRadius: "12px",
    },
    profileHeading: {
      color: "#090909",
      fontSize: "34px",
      fontWeight: 700,
    },
    studentHeader: {
      color: "#090909",
      fontSize: "22px",
      fontWeight: 600,
    },
    headerWrappper: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      margin: "15px 0 30px 0",
    },
    backIcon: {
      cursor: "pointer",
      fontSize: "34px",
    },
    headLineWrapper: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
      cursor: "pointer",
    },
    fieldWrapper: {
      display: "flex",
      gap: "15px",
      alignItems: "center",
      padding: "25px",
    },
    boxWrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "0 25px",
    },
    parentHeaderWrapper: {
      backgroundColor: "#F1F1F1",
      height: "1px",
    },
    contentCenter: {
      display: "flex",
      alignItems: "center",
    },
    parentBodyWrapper: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "0 25px",
    },
    rootBox: {
      padding: "40px",
    },
    imageMargin: {
      margin: "0 8px",
    },
    flexBox: {
      display: "flex",
      flexDirection: "column",
    },
    gridBoxWrapper: {
      padding: "0 25px 25px 25px",
    },
    paper: {
      padding: theme.spacing(2),
      background: "#f1f1f1",
      boxShadow: "none",
      borderRadius: "12px",
    },
    gridElement: {
      background: "#f1f1f1",
      padding: "12px",
      borderRadius: "12px",
    },
    fontBold600: {
      fontWeight: 600,
    },
    profileImgBox: {
      width: "50px",
      height: "50px",
      borderRadius: "50%",
    },
    teacherProfileImg: {
      width: "50px",
      height: "50px",
      objectFit: "contain",
    },
    profileImg: {
      width: "50px",
      height: "50px",
      borderRadius: "50%",
      objectFit: "contain",
    },
    emptyProfileImg: {
      width: "inherit",
      height: "inherit",
      color: "#ccc",
    },
    capitalText: {
      textTransform: "capitalize",
    },
    wordwrap: {
      wordBreak: "break-all",
    },
    studentParentBox: {
      gap:"7px",
      height: "30px",
      display: "flex",
      alignItems: "center",
    },
  });
// Customizable Area End

// Customizable Area Start
export class StudentProfile extends StudentProfileController {
  // Customizable Area Start
  constructor(props: Props) {
    super(props);
  }
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const {studentInfo} = this.state
    return (
      <Box
        className={`${classes.childMainWrapperPadding} ${
          classes.highlightBgColor
        } ${classes.secondaryBgColor} ${classes.rootBox}`}
        height={"100%"}
      >
        <Box marginTop={"1%"}>
          <Typography
            className={`${classes.textCommonColor} ${classes.fontText14}`}
            component={"span"}
          >
            {`Dashboard /`}
          </Typography>
          <Typography
            className={`${classes.primaryColor5} ${classes.fontText14} ${
              classes.fontBold600
            }`}
            component={"span"}
          >
            {` My Profile`}
          </Typography>
        </Box>
        <Box className={classes.headerWrappper}>
          <Box className={classes.headLineWrapper} onClick={this.handleGoBack}>
            <KeyboardBackspaceIcon
              className={`${classes.backIcon} ${classes.textPrimaryColor}`}
            />
            <Typography
              className={`${classes.profileHeading} ${
                classes.textPrimaryColor
              }`}
            >
              My Profile
            </Typography>
          </Box>
          <GearIconDropDown
            data-testid="profile-settings"
            options={this.state.profileSettingOptions}
          />
        </Box>
        <Box className={`${classes.fieldBox} ${classes.bgColor}`}>
          <Box className={classes.fieldWrapper}>
            <Box className={classes.profileImgBox}>
              {studentInfo?.profileImg ? (
                <CustomImageComponentWeb
                  altText="teacher-profile"
                  src={studentInfo.profileImg}
                  imgStyles={{
                    width: "50px",
                    height: "50px",
                    objectFit: "cover",
                    borderRadius: "50%"
                  }}
                  isUserProfileImg
                />
                
              ) : (
                <AccountCircleIcon className={`${classes.emptyProfileImg}`} />
              )}
            </Box>
            <Typography
              className={`${classes.studentHeader} ${classes.textPrimaryColor}`}
            >
              {`${studentInfo.firstName} ${studentInfo.lastName}`}
            </Typography>
          </Box>
          <Grid
            spacing={2}
            className={classes.gridBoxWrapper}
            container
            direction="row"
          >
            <Grid className={classes.wordwrap} item xs={12} md={6} lg={4}>
              <Typography className={classes.inputLabel}>
                Email ID:{" "}
                <Typography
                  component={"span"}
                  className={`${classes.textPrimaryColor} ${classes.fieldValue}`}
                >
                  {studentInfo.email}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Typography className={classes.inputLabel}>
                ID Number:{" "}
                <Typography
                  component={"span"}
                  className={`${classes.fieldValue} ${classes.textPrimaryColor}`}
                >
                  {studentInfo.uniqId}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Typography className={classes.inputLabel}>
                Curriculum:{" "}
                <Typography
                  component={"span"}
                  className={`${classes.fieldValue} ${classes.textPrimaryColor}`}
                >
                  {studentInfo.curriculums.name}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Typography className={classes.inputLabel}>
                Date of Birth:{" "}
                <Typography
                  component={"span"}
                  className={`${classes.fieldValue} ${classes.textPrimaryColor}`}
                >
                  {studentInfo.dob}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Typography className={classes.inputLabel}>
                Grade Level:{" "}
                <Typography
                  component={"span"}
                  className={`${classes.fieldValue} ${classes.textPrimaryColor}`}
                >
                  {studentInfo?.gradeDetails?.name}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Typography className={classes.inputLabel}>
                school Year Title:{" "}
                <Typography
                  component={"span"}
                  className={`${classes.fieldValue} ${classes.textPrimaryColor}`}
                >
                  {studentInfo?.schoolYear}
                </Typography>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Typography className={classes.inputLabel}>
                Role:{" "}
                <span
                  className={`${classes.textPrimaryColor} ${classes.fieldValue} ${classes.capitalText}`}
                >
                  {studentInfo.role}
                </span>
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <Typography className={classes.inputLabel}>
              Class:{" "}
                <Typography
                  component={"span"}
                  className={`${classes.fieldValue} ${classes.textPrimaryColor}`}
                >
                  {studentInfo?.classDetail?.name}
                </Typography>
              </Typography>
            </Grid>
            
            
            <Grid item xs={12} md={6} lg={4}>
              <Typography className={classes.inputLabel}>
              Expiring On:{" "}
                <Typography
                  component={"span"}
                  className={`${classes.fieldValue} ${classes.textPrimaryColor}`}
                >
                  {studentInfo?.expiringDate}
                </Typography>
              </Typography>
            </Grid>
            {studentInfo.customFields.map((item: any) => (
              <Grid key={item?.id} item xs={12} md={6} lg={4}>
                <Typography className={classes.inputLabel}>
                  {item?.label}:{" "}
                  <Typography
                    component={"span"}
                    className={`${classes.textPrimaryColor} ${classes.fieldValue} `}
                  >
                    {item?.value || "NA"}
                  </Typography>
                </Typography>
              </Grid>
            ))}
            <Grid item xs={12} md={6} lg={4}>
              <Typography className={classes.inputLabel}>
                Status:{" "}
                <span
                  className={`${classes.textPrimaryColor} ${classes.fieldValue} ${classes.capitalText}`}
                >
                  {studentInfo.status}
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Box>
          {studentInfo?.parentsList?.map((parent, index: number) => (
               <Box key={index} className={`${classes.fieldBox} ${classes.bgColor}`}>
               <Box className={classes.fieldWrapper}>
               <Typography
                  className={`${classes.textPrimaryColor} ${classes.fontText20}`}
                  style={{ fontWeight: "bold" }}
                >
                  {`Parent ${index + 1} Details`}
                </Typography>
               </Box>
               <Box className={`${classes.parentHeaderWrapper} `} style={{padding:"0.5px"}} />
               <Grid
                 spacing={2}
                 className={classes.gridBoxWrapper}
                 style={{paddingTop:"25px",margin:0}}
                 container
                 direction="row"
               >
                 <Grid className={classes.wordwrap} item xs={12} md={6} lg={4}  >
                  <Box className={classes.studentParentBox}>
                   <Typography className={classes.inputLabel} >
                     Name:{" "}
                   
                   </Typography>
                   <CustomImageComponentWeb
                      altText="Parent Profile"
                      imgStyles={{
                        width: "40px",
                        height: "40px",
                        objectFit: "cover",
                        borderRadius: "50%",
                        marginLeft: "7px",
                        marginRight: "7px",
                      }}
                      src={parent.profileUrl}
                      isUserProfileImg
                    />
                     <Typography
                       component={"span"}
                       className={`${classes.textPrimaryColor} ${classes.fieldValue}`}
                     >
                       {parent.name}
                     </Typography>
                   </Box>
                 </Grid>
                 <Grid item xs={12} md={6} lg={4}>
                 <Box className={classes.studentParentBox}>
                   <Typography className={classes.inputLabel}>
                   Relationship:{" "}
                   </Typography>
                   <Typography
                       component={"span"}
                       className={`${classes.fieldValue} ${classes.textPrimaryColor}`}
                     >
                       {parent.relationship}
                     </Typography>
                   </Box>
                 </Grid>
                 <Grid item xs={12} md={6} lg={4}>
                 <Box className={classes.studentParentBox}>
                   <Typography className={classes.inputLabel}>
                   Contact Number:{" "}
                     
                   </Typography>
                   <Typography
                       component={"span"}
                       className={`${classes.fieldValue} ${classes.textPrimaryColor}`}
                     >
                       {parent.contactNum}
                     </Typography>
                   </Box>
                 </Grid>
                 <Grid item xs={12} md={6} lg={4}>
                 <Box className={classes.studentParentBox}>
                   <Typography className={classes.inputLabel}>
                    Email ID:{" "}
                   </Typography>
                   <Typography
                       component={"span"}
                       className={`${classes.fieldValue} ${classes.textPrimaryColor}`}
                     >
                      {parent.email}
                     </Typography>
                   </Box>
                 </Grid>
  
                 <Grid item xs={12} md={6} lg={4}>
                 <Box className={classes.studentParentBox}>
                   <Typography className={classes.inputLabel}>
                     
                      Role:{" "}
                    
                   </Typography>
                   <Typography
                       component={"span"}
                       className={`${classes.fieldValue} ${classes.textPrimaryColor}`}
                     >
                        {parent.role}
                     </Typography>
                   </Box>
                 </Grid>
               </Grid>
             </Box>
          ))}
      </Box>
    );
    // Customizable Area End
  }
}
// Customizable Area End

// Customizable Area Start
const combinedStyle = combineStyles(customThemeStyles, styles);
export default withStyles(combinedStyle)(StudentProfile);
// Customizable Area End
